import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { getStatusList } from 'redux/pages/Administration/administrationSlice';
import { getSalesOfficeLookup } from 'redux/pages/Inspection/Contracts/contractSlice';
import { Calendar } from 'primereact/calendar';
import { BsExclamationCircleFill } from 'react-icons/bs';

interface Props {
    getTableColumns?: (data: any) => void,
}

export const ContractTableData: React.FC<Props> = ({
    getTableColumns = () => { },
}) => {
    const statusList = useAppSelector((state) => state.administration.statusList);
    const salesOfficeList = useAppSelector((state) => state.contract.salesOfficeData);
    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails)
    const [statusLookup, setStatusLookup] = useState([{ label: "", value: "" }]);
    const [salesOfficeLookup, setSalesOfficeLookup] = useState([{ label: "", value: "" }]);
    const [countryId, setCountryId] = useState<any>(220)
    const dispatch = useDispatch<any>()

    const contractColumns = useRef<any>();

    useEffect(() => {
        dispatch(getStatusList());
        dispatch(getSalesOfficeLookup());
    }, [dispatch])

    useEffect(() => {
        if (loggedInUserData) {
            setCountryId(loggedInUserData.userDefaults?.countryId)
        }
    }, [loggedInUserData])

    useEffect(() => {
        if (statusList !== "" && salesOfficeList !== "") {
            if (statusList?.status?.length) {
                let status: { label: string, value: string }[] = [];
                statusList?.status.map((item: any) => {
                    if (item.statusName !== "Closed") {
                        status.push({ label: item.statusName, value: item.statusId });
                    }
                    return status;
                })
                setStatusLookup(status)

            }
            if (salesOfficeList?.length) {
                let salesOffice: { label: string, value: string }[] = [];
                salesOfficeList?.map((item: any) => {
                    return salesOffice.push({ label: item.salesOfficeName, value: item.uniqueSalesOfficeId });
                })
                setSalesOfficeLookup(salesOffice)

            }
        }
    }, [statusList, salesOfficeList])

    const CONTRACT_COLS = [
        {
            field: 'customerName',
            header: 'Customer',
            editor: (options) => customerEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        },
        {
            field: 'contractNumber',
            header: 'Contract Number',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        },
        {
            field: 'contractName',
            header: 'Contract Name',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'startDate',
            header: 'Start Date',
            editor: (options) => dateEditor(options),
            style: { flex: '1 0 100px' },
            sortable: true,
        },
        {
            field: 'endDate',
            header: 'End Date',
            editor: (options) => dateEditor(options),
            style: { flex: '1 0 100px' },
            sortable: true,
        },
        {
            field: 'notes',
            header: 'Notes',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 250px' },
            sortable: true,
        },
        {
            field: 'status',
            header: 'Status',
            editor: (options) => statusEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        },
        {
            field: 'salesOfficeName',
            header: 'Sales Office',
            editor: (options) => salesOfficeEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        },

    ];

    const getErrorMsg = options => {
        options.value = options.value ? options.value.trim() : '';
        const { field, value } = options;
        const FIELD_REQUIRED = 'This field is required';
        const NUMBER_VALID = 'Enter a valid Contract Number';
        const FIELD_VALID = 'Enter a valid Contract Name';
        switch (field) {
            case 'contractName': {
                if (!value?.trim())
                    return FIELD_REQUIRED;
                else if(value.length > 100)
                    return FIELD_VALID;
                break;
            }
            case 'contractNumber': {
                if (!isPatternValid(value))
                    return NUMBER_VALID
                else if (!value)
                    return FIELD_REQUIRED
                break;
            }
            default: {
                return ''
            }
        }
    };

    const isPatternValid = (contractNumber) => {
        if (!contractNumber) {
            return true;
        }
        if (countryId === 220) {
            return /^([5]\d{9}|44OP[-]\d{6}|26\d{8}|44OC[-]\d{7})$/.test(contractNumber);
        } else {
            return /^(([4]\d{7})|((60|13)\d{5})|([a-zA-Z][-][0-9]{2}[-][a-zA-Z][-][0-9]{4})|([a-zA-Z][-]SI[-][0-9]{5}))$/.test(contractNumber);
        }
    }

    const statusEditor = (options) => {
        return (
            <Dropdown disabled value={options.value} options={statusLookup} optionLabel="label" optionValue='label'
                onChange={(e) => options.editorCallback(e.value)} placeholder="Select Status"
                itemTemplate={(option) => {
                    return <span className='status-badge'>{option.label}</span>
                }} />
        );
    }

    const customerEditor = (options) => {
        return <InputText disabled type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const salesOfficeEditor = (options) => {
        return (
            <Dropdown value={options.value} options={salesOfficeLookup} optionLabel="label" optionValue='label'
                onChange={(e) => options.editorCallback(e.value)} placeholder="Select Sales Office"
                itemTemplate={(option) => {
                    return <span className='status-badge'>{option.label}</span>
                }} />
        );
    }


    const textEditor = (options) => {
        const errorMsg = getErrorMsg(options);
        return <>
            <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
            {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
        </>
    }

    const dateEditor = (options) => {
        let date = new Date(options.value)
        let timezoneoffset = date.getTimezoneOffset();
        let localDate = new Date();
        if(timezoneoffset >=0)
        localDate = new Date(date.getTime() + (timezoneoffset) * 60000)
        else
        localDate = new Date(date.getTime() - (timezoneoffset) * 60000)
        return <Calendar id="calendar" dateFormat="yy-mm-dd" readOnlyInput value={localDate} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    contractColumns.current = CONTRACT_COLS;

    useEffect(() => {
        if (salesOfficeLookup.length > 0 && statusLookup.length > 0) {
            getTableColumns(contractColumns.current);
        }
    }, [getTableColumns, salesOfficeLookup, statusLookup]);


    return (
        <></>
    )

}

export default ContractTableData;