import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

import { BsExclamationCircleFill } from 'react-icons/bs';

const statusList = [
  { label: 'Active', value: 'Active' },
  { label: 'Blocked', value: 'Blocked' },
  { label: 'Deleted', value: 'Deleted' },
  { label: 'Not Activated', value: 'Not Activated' }
];

const getErrorMsg = options => {
  options.value = options.value ? options.value.trim() : '';
  const { field, value, rowData } = options;
  const FIELD_REQUIRED = 'This field is required';
  const EMAIL_VALID = 'Enter a valid Email';
  const NUMBER_VALID = 'Enter a valid Customer Number';
  const PHONE_VALID = 'Enter a valid Phone Number';
  const FAX_VALID = 'Enter a valid Fax';
  switch (field) {
    case 'customerName':
    case 'customerAddress':
    case 'cityStateZip':
    case 'contact': {
      if (!value.trim())
        return FIELD_REQUIRED;
      break;
    }
    case 'phone': {
      if (!isPhoneValid(value.trim()))
        return PHONE_VALID
      else if (!value.trim())
        return FIELD_REQUIRED
      break;
    }
    case 'fax': {
      if (!isFaxValid(value.trim()))
        return FAX_VALID
      else if (!value.trim())
        return FIELD_REQUIRED
      break;
    }
    case 'customerNumber': {
      if (!isPatternValid(value, rowData))
        return NUMBER_VALID
      else if (!value)
        return FIELD_REQUIRED
      break;
    }
    case 'email': {
      if (!isEmailValid(value.trim()))
        return EMAIL_VALID
      else if (!value.trim())
        return FIELD_REQUIRED
      break;
    }
    default: {
      return ''
    }
  }
};

const isPhoneValid = (phone) => {
  if (!phone) {
    return true;
  }
  return /^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?None]*$/.test(phone);
}

const isFaxValid = (fax) => {
  if (!fax) {
    return true;
  }
  return /^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?None]*$/.test(fax);
}

const isPatternValid = (customerNumber, rowData) => {
  if (!customerNumber) {
    return true;
  }
  if (rowData.countryId === 220)
    return /^[1,3][0-9]{7}$/.test(customerNumber);
  else
    return /^[6][0-9]{5}$/.test(customerNumber);
}

const isEmailValid = (email) => {
  if (!email) {
    return true;
  }
  return /^None$|^\S+@\S+\.\S+$/.test(email);
}

const statusEditor = (options) => {
  return (
    <Dropdown value={options.value} options={statusList} optionLabel="label" optionValue="value"
      onChange={(e) => options.editorCallback(e.value)} placeholder="Select Status"
      itemTemplate={(option) => {
        return <span className='status-badge'>{option.label}</span>
      }} />
  );
}

const textEditor = (options) => {
  const errorMsg = getErrorMsg(options);
  return <>
    <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} maxLength={100} onChange={(e) => options.editorCallback(e.target.value)} />
    {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
  </>
}

const phoneEditor = (options) => {
  const errorMsg = getErrorMsg(options);
  return <>
    <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} maxLength={13} onChange={(e) => options.editorCallback(e.target.value)} />
    {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
  </>
}

const faxEditor = (options) => {
  const errorMsg = getErrorMsg(options);
  return <>
    <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} maxLength={25} onChange={(e) => options.editorCallback(e.target.value)} />
    {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
  </>
}

const dateEditor = (options) => {
  return <Calendar id="calendar" dateFormat="yy-mm-dd" readOnlyInput value={new Date(options.value)} onChange={(e) => options.editorCallback(e.target.value)} />
}

export const CUSTOMER_COLS = [
  {
    field: 'customerName',
    header: 'Customer Name',
    editor: (options) => textEditor(options),
    style: { flex: '1 0 250px' },
    sortable: true,
  },
  {
    field: 'customerNumber',
    header: 'Customer Number',
    editor: (options) => textEditor(options),
    style: { flex: '1 0 150px' },
    sortable: true,
  },
  {
    field: 'customerAddress',
    header: 'Address',
    editor: (options) => textEditor(options),
    style: { flex: '1 0 200px' },
    sortable: true,
  },
  {
    field: 'cityStateZip',
    header: 'City, State, Zip',
    editor: (options) => textEditor(options),
    style: { flex: '1 0 200px' },
    sortable: true,
  },
  {
    field: 'phone',
    header: 'Phone',
    editor: (options) => phoneEditor(options),
    style: { flex: '1 0 100px' },
    sortable: true,
  },
  {
    field: 'email',
    header: 'Email',
    editor: (options) => textEditor(options),
    style: { flex: '1 0 150px' },
    sortable: true,
  },
  {
    field: 'fax',
    header: 'Fax',
    editor: (options) => faxEditor(options),
    style: { flex: '1 0 100px' },
    sortable: true,
  },
  {
    field: 'contact',
    header: 'Contact',
    editor: (options) => textEditor(options),
    style: { flex: '1 0 140px' },
    sortable: true,
  },
  {
    field: 'accountExpiryDate',
    header: 'Account Expiry Date',
    editor: (options) => dateEditor(options),
    style: { flex:'1 0 200px' },
    sortable: true,
  },
  {
    field: 'status',
    header: 'Status',
    editor: (options) => statusEditor(options),
    style: { flex: '1 0 100px' },
    sortable: true,
  }
];