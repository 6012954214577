import React from 'react';
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import { APP_ROUTES } from 'constants/AppRoutes';

import { setIsAuthenticated } from 'redux/pages/Login/loginSlice';

const PrivateRoute = (props: { children: React.ReactNode }): JSX.Element => {
  const { children } = props

  const dispatch = useAppDispatch();

  const isLoggedIn: boolean = useAppSelector(state => state?.login?.isAuthenticated);
  const location = useLocation()

  useEffect(() => {
    dispatch(setIsAuthenticated(true));
  }, [dispatch]);

  return  (
    <>{children}</>
  ) 
  
};


export default PrivateRoute;