import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {zlink, baseURL} from "../../../../service/HttpService"
import { AppThunk } from "../../../store";

export interface SearchState {
    errors: any;
    siteDetails:any;
	contractSearchDetails: any;
	customerDetails:any;
	userDetails:any;
	notificationNumDetails: any;
	inspectionDetails: any;
	systemDetails:any;
}

let initialState: SearchState = {
    errors: "",
    siteDetails:[],
	contractSearchDetails: [],
	customerDetails:[],
	userDetails:[],
	notificationNumDetails: [],
	inspectionDetails: [],
	systemDetails:[],
};

const SearchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
			state.errors = payload;
		},
        setSiteDetails: (state, {payload}: PayloadAction<any>) => {
			state.siteDetails = payload;
		},
		setContractSearchDetails: (state, { payload }: PayloadAction<any>) => {
			state.contractSearchDetails = payload;
		},
		setCustomerDetails: (state, {payload}: PayloadAction<any>) => {
			state.customerDetails = payload;
		},
		setUserDetails: (state, {payload}: PayloadAction<any>) => {
			state.userDetails = payload;
		},
		setNotificationNumDetails: (state, {payload}: PayloadAction<any>) => {
			state.notificationNumDetails = payload;
		},
		setInspectionDetails: (state, {payload}: PayloadAction<any>) => {
			state.inspectionDetails = payload;
		},
		setSystemDetails: (state, {payload}: PayloadAction<any>) => {
			state.systemDetails = payload;
		},
    }
});

export const getSiteSearchDetails = (siteSearchText:any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/common/search/site/${siteSearchText}/sitelist`,null);
		if (res.status === "done") {
			dispatch(setSiteDetails(res?.data?.siteDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getContractSearchDetails = (searchText:any): AppThunk => async (dispatch) => {
	try {
	  const res = await zlink.post(`${baseURL}api/v1/common/search/contract/${searchText}/contractlist`,null);
	  if (res.status === "done") {
		dispatch(setContractSearchDetails(res?.data?.contractDetails));
	  } else {
		dispatch(setErrors(res.error));
	  }
	} catch (error) {
	  dispatch(setErrors(error));
	}
  };

export const getCustomerSearchDetails = (customerSearchText:any): AppThunk => async (dispatch) => {
	try {
		let body = {
			SearchText: customerSearchText
		  };
		const res = await zlink.post(`${baseURL}api/v1/admin/customer/list/search`,body);
		if (res.status === "done") {
			dispatch(setCustomerDetails(res?.data?.customerDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getUserSearchDetails = (userSearchText:any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/users?keyword=${encodeURIComponent(userSearchText)}&selectedUserId`);
		if (res.status === "done") {
			dispatch(setUserDetails(res?.data?.userDetails));
		}
	 else {
		dispatch(setErrors(res.error));
	}
} catch (error) {
	dispatch(setErrors(error));
}
}
export const getInspectionDetails = (searchText:any): AppThunk => async (dispatch) => {
	try {
		let body = {
			SearchText: searchText,
			IsNotificationNumberSearch: false
		  };
		const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/search`,body);
	  if (res.status === "done") {
		dispatch(setInspectionDetails(res?.data?.taskDetails));
	  } else {
		dispatch(setErrors(res.error));
	  }
	} catch (error) {
	  dispatch(setErrors(error));
	}
  };

  export const getNotificationNumDetails = (searchText:any): AppThunk => async (dispatch) => {
	try {
		let body = {
			SearchText: searchText,
			IsNotificationNumberSearch: true
		  };
	  const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/search`,body);
	  if (res.status === "done") {
		dispatch(setNotificationNumDetails(res?.data?.taskDetails));
	  } else {
		dispatch(setErrors(res.error));
	  }
	} catch (error) {
	  dispatch(setErrors(error));
	}
  };
export const getSystemSearchDetails = (systemSearchText:any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/common/search/system/${systemSearchText}/systemlist`,null);
		if (res.status === "done") {
			dispatch(setSystemDetails(res?.data?.systemDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};


export const {
    setErrors,
    setSiteDetails,
	setCustomerDetails,
	setNotificationNumDetails,
	setInspectionDetails,
	setSystemDetails,
	setContractSearchDetails,
	setUserDetails
 } = SearchSlice.actions;
export default SearchSlice.reducer;
