import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";

import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Calendar } from "primereact/calendar";
import { getNotificationMessage, sendNotifcationMessage } from "redux/pages/Dashboard/UserMenu/NotificationMessageSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import JoditEditor from 'jodit-react';
import moment from "moment";
import { Tooltip } from "primereact/tooltip";
interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onSendNotificationMessage : (info: any,setShowModal:any) => void;
}

type FormData = {
  expiryDate: any;
};

export const AddNotificationMessage: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  onSendNotificationMessage = () => {}
}) => {

  const dispatch = useDispatch<any>();
  const notificationMessageData = useAppSelector((state) => state.notificationMessage.notificationMessage);
  const [notificationMessage, setNotificationMessage] = useState<any>();
  const notificationMessageForm: any = useRef();
  const [currentnotificationMessage, setCurrentNotificationMessage] = useState<any>();
  const editorRef: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
        expiryDate: null,
    },
  });
const options = [ 'bold', 'italic',
'underline', 'strikethrough','superscript', 'subscript','|', 
'ul', 'ol', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align','|',
'hr','|', 'fullsize', 'brush','|', 'table', 'link', '|', 'undo', 'redo','|','eraser','selectall','paragraph','preview','|',
{
  name: 'Clear',
  //iconURL: 'https://img.icons8.com/ios-filled/50/000000/delete-sign.png', 
  exec: (editor) => {
    editor.setEditorValue(''); 
  },
  tooltip: 'Clear All'
},'|'
];
  const config = useMemo(
    () => ({
     readonly: false,
     height:430 ,
     placeholder: '',
     defaultLineHeight: 1.5,
     buttons: options,
     buttonsMD: options,
     buttonsSM: options,
     buttonsXS: options,
     statusbar: false,
     sizeLG: 900,
     sizeMD: 700,
     sizeSM: 400,
     toolbarAdaptive: false,
    }),
    [],
   );

  useEffect(() => {
    dispatch(getNotificationMessage())
  }, []);


  useEffect(() => {
    if (notificationMessageData && Object.keys(notificationMessageData).length > 0) {
      setValue("expiryDate", new Date(notificationMessageData?.expiryDate));
      setCurrentNotificationMessage(
        notificationMessageData?.message)
      setNotificationMessage(notificationMessageData?.message)
    }
  }, [notificationMessageData])

  const formSubmitHandler = (data: any) => {
    notificationMessageForm.current.requestSubmit();
  };

  const onNotificationMessageFormSubmit = (data: any) => {
      let info = {
        expiryDate: moment(data.expiryDate).format("YYYY-MM-DD").toString(),
        message: notificationMessage
      }
      onSendNotificationMessage(info,setShowModal)
    
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
  }, [])

  const handleKeyPress = (e) => {
    if (e.key == "Escape") {
      document.documentElement.classList.remove('jodit_fullsize-box_true');
      document.body.classList.remove('jodit_fullsize-box_true');
    }
  }

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text" style={{zIndex: "9999" }}>
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };


  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button label="Post" className="update btn"  autoFocus onClick={formSubmitHandler}/>
      </>
    );
  };

  const handleChange = (content) => {
    setNotificationMessage(content);
  }

  return (
    <>
     <Dialog
        header="Add Notification Message"
        visible={showModal}
        style={{ width: "45vw", height: "82vh"  }}
        onHide={() => setShowModal(false)}
        footer={ renderFooter() }
        className="display-settings personal selectsite"
      >
        <Tooltip target=".p-dialog-header .p-dialog-header-icons .p-dialog-header-close" content="Close" />
        <form onSubmit={handleSubmit(onNotificationMessageFormSubmit)} ref={notificationMessageForm}>
          <div className="notification-message-main-wrapper">
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
              <div className="left-sider-label">
                <div className="p-float-label">
                  <p className="label mandatory">Expiry Date</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label text-container">
                  <Controller
                    name="expiryDate"
                    control={control}
                    rules={{
                      required: " Expiry Date is required.",
                      validate: (value) => {
                        return (
                          new Date(value).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                            ? "Expiry Date should be greater than or equal to current date" : true)
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <Calendar
                      style={{padding:"0px"}}
                        id="calendar"
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        dateFormat="yy-mm-dd"
                        {...field}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />
                  {getFormErrorMessage("expiryDate")}
                </span>
              </div>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label">
                <div className="p-float-label">
                  <p className="label">Message</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label text-container">
                  <div className="message-wrapper" >    
                    <JoditEditor    
                      ref={editorRef}
                      value={currentnotificationMessage}
                      config={config}
                      onChange={(htmlString) => handleChange(htmlString)}
                    />         
                  </div>    
                </span>
              </div>
            </div>
          </div>
          </div>
        </form>
           
      </Dialog>
    </>
  );
};

export default AddNotificationMessage;
