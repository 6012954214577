import React, { useState, useCallback, useEffect } from "react";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Controller, useForm } from "react-hook-form";
//icons
import { BsPaperclip, BsTrashFill } from "react-icons/bs";
//component
import DataTableComponent from "components/Table/DataTable";
import { REPORTS_COLS } from "./ReportsTableData";
import UploadFinalReport from "./UploadFinalReport";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import {fetchLoggedInUserDetails} from "../../redux/pages/Administration/administrationSlice";
//redux
import {
  clearDeletedReportDetails,
  getReportList,
  getAllSystemList,
  onDownloadDraftReport,
  onDownloadFinalReport,
  onRemoveReports,
  onViewDraftReport,
  onViewFinalReport,
  uploadFinalReport,
  setDefaultSystemDetails,
  setReportList,
  setSystemList,
  setReportListPageDetails,
  checkIsInspectionTaskCompleted
} from "../../redux/pages/Inspection/InspectionReports/inspectionReportsSlice";
import { useAppSelector } from "redux/hooks";
import { showUI } from "service/GeneralUtility";
import DocumentViewer from "components/common/DocumentViewer/DocumentViewer"

interface Isystem {
  name: string;
  id: string;
}
interface IReports { }

const Reports: React.FC<IReports> = () => {
  const { control, setValue } = useForm();

  const dispatch = useDispatch<any>();

  const [selectedSystem, setSelectedSystem] = useState<Isystem>({
    name: "",
    id: ""
  });
 
  const [filterExpanded] = useState<any>(true);
  const [systemLookup, setSystemLookup] = useState([{ name: "", id: "" }]);
  const [showSystemGrid] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [showUploadXMLModal, setShowUploadXMLModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [showViewModal, setShowViewModal] = useState<any>(false);
  const [streamData, setStreamData] = useState<any>('');
  const [fileName, setFileName] = useState("");
  const [currentStart, setCurrentStart] = useState(0);
  const [acceptedFileTypes, setAcceptedFileTypes] = useState<any>(["application/pdf"])
  const [reportType, setReportType] = useState<any>("");
  const [pageDetails, setPageDetails] = useState<any>({});
  const [systemDropDownList, setSystemDropDownList] = useState<any>([]);
  const [loginDetails, setLoginDetails] = useState<any>();
  const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);
  const systemsList = useAppSelector((state) => state.inspectionReport.systemList);
  const reportList = useAppSelector((state) => state.inspectionReport.reportList);
  const reportListPageDetails = useAppSelector((state) => state.inspectionReport.reportListPageDetails);
  const deletedReportDetails = useAppSelector((state) => state.inspectionReport.deletedReportDetails);
  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
  const isFinalReportUploaded = useAppSelector((state) => state.inspectionReport.isFinalReportUploaded);
  const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
  const isInspectiontaskCompleted = useAppSelector((state) => state.inspectionReport.isInspectiontaskcompleted);

  useEffect(() => {
    dispatch(getAllSystemList());
    dispatch(fetchLoggedInUserDetails());

    return (() => {
      dispatch(setSystemList([]));
      dispatch(setReportList([]));
      dispatch(setReportListPageDetails(""));
    });
  }, [dispatch]);

  useEffect(() => {
    if (systemsList) {
      setSystemDropDownList(systemsList);
    }
  }, [dispatch, systemsList]);

  useEffect(() => {
    if (systemDropDownList?.length > 0) {
      
      let system: any = [];
      systemsList.forEach((item: any) => {
        return system.push({ name: item.systemName, id: item.uniqueSystemId });
      });
      setSystemLookup(system);
      loginDetailsCallback();
    }
  }, [dispatch, systemDropDownList,loggedInUserDetails]);

  const loginDetailsCallback = useCallback(() => {
   
    if (loggedInUserDetails) {
      setLoginDetails(loggedInUserDetails);
    }
  }, [dispatch, loggedInUserDetails]);

  useEffect(() => {
    if (selectedSystem && selectedSystem?.id !== '') {
      dispatch(getReportList(selectedSystem?.id, 1));
    }
  }, [dispatch, selectedSystem, rowsPerPage]);

  useEffect(() => {
    if (loginDetails && Object.keys(loginDetails).length !== 0 && systemDropDownList.length > 0) {
      if (loginDetails?.userDefaults?.system?.uniqueSystemId) {
        const commonSystem = systemDropDownList.find(item => item.uniqueSystemId === loginDetails?.userDefaults?.system?.uniqueSystemId);
        if (commonSystem) {
          setValue("system", { name: loginDetails?.userDefaults?.system?.systemName, id: loginDetails?.userDefaults?.system?.uniqueSystemId });
          setSelectedSystem((selectedSystem: any) => ({
            ...selectedSystem,
            ["id"]: loginDetails?.userDefaults?.system?.uniqueSystemId,
            ["name"]: loginDetails?.userDefaults?.system?.systemName,
          }));
        } else {
          setValue("system", { name: systemDropDownList[0]?.systemName, id: systemDropDownList[0]?.uniqueSystemId });
          setSelectedSystem((selectedSystem: any) => ({
            ...selectedSystem,
            ["id"]: systemDropDownList[0]?.uniqueSystemId,
            ["name"]: systemDropDownList[0]?.systemName
          }));
        }
      } else {
        setValue("system", { name: systemDropDownList[0]?.systemName, id: systemDropDownList[0]?.uniqueSystemId });
        setSelectedSystem((selectedSystem: any) => ({
          ...selectedSystem,
          ["id"]: systemDropDownList[0]?.uniqueSystemId,
          ["name"]: systemDropDownList[0]?.systemName
        }));
      }
    }
    else if (systemDropDownList === null || systemDropDownList.length === 0) {
      dispatch(setReportList([]));
    }
  }, [loginDetails]);

  useEffect(() => {
    if (reportListPageDetails) {
      setPageDetails(reportListPageDetails);
    }
  }, [reportListPageDetails]);

  useEffect(() => {
    if (rowsPerPage && rowsPerPage > 0) {
      let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
      setPageDetails(modifiedPageDetails);
      setCurrentStart(1)
      setPageIndex(0)
    }
  }, [rowsPerPage]);

  useEffect(() => {
       if(reportList && reportList?.length){
          setSelectedRows([reportList[0]]);
       } 
    }, [reportList]);

  const handleRowSelect = useCallback((data: any) => {
		if (data && data.length) {
			setSelectedRows([...data]);			
		}  else {
			setSelectedRows(reportList.length ? [reportList[0]] : []);
		}
	}, [reportList]);

  
  useEffect(() => {
    if (isDelete && isInspectiontaskCompleted?.length !== 0) {
      if (isInspectiontaskCompleted.isInspectionTaskCompleted) {
        if (showUI(userPrivileges, 'SIETAP_FS_DELETE_REPORTS')) {
          setMessage("Are you sure you want to delete the selected Reports?");
          setDisplayConfirmModal(true);
          setIsDelete(false)
        } else {
          setMessage("You do not have the privilege to delete the Selected Drafted Report(s) as Inspection Task is completed.");
          setDisplayInfoModal(true);
          setIsDelete(false)
        }
      } else {
        setMessage("Are you sure you want to delete the selected Reports?");
        setDisplayConfirmModal(true);
        setIsDelete(false)
      }
    }

  }, [isInspectiontaskCompleted, isDelete])



  useEffect(() => {
    if (deletedReportDetails) {
      setMessage("Report(s) deleted");
      setDisplayInfoModal(true);
      let datas = JSON.parse(JSON.stringify(reportList));
      deletedReportDetails?.SytemPanelInspectionReportIds.forEach((element) => {
        datas = datas.filter(
          (item: any) => item.systemPanelInspectionReportId !== element
        );
      });
      dispatch(setReportList(datas));
      dispatch(clearDeletedReportDetails(""))
    }
  }, [deletedReportDetails]);

  const onSystemChange = (data: any) => {
    if (data && data.value) {
      setSelectedRows([]);
      setSelectedSystem((selectedSystem: any) => ({
        ...selectedSystem,
        ["id"]: data?.value?.id,
        ["name"]: data?.value?.name
      }));
      dispatch(setDefaultSystemDetails(data?.value?.id));
    }
  };

  const getSystemStatus = (data: any) => {
    let status = false;
    if (data && data.id) {
      let item = systemsList.find(item => item.uniqueSystemId === data.id);
      if (item) {
        status = (item.status && (item.status === "Active")) ? true: false;
      }
    }
    return status;
  }

  const systemHeaderDropdowns = (data, label) => (
    <span className="p-float-label mr-4">
      <Controller
        name="system"
        control={control}
        rules={{ required: "This field is required." }}
        render={({ field, fieldState }) => (
          <Dropdown
            inputId="dropdown"
            className={classNames("w-100 error-tooltip", {
              error: fieldState.invalid,
            })}
            {...field}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.value);
              onSystemChange(e.target);
            }}
            options={data}
            optionLabel="name"
          />
        )}
      />
      <label className="mandatory" htmlFor="dropdown">
        {label}
      </label>
    </span>
  );

  const deleteReports = () => {
    if ((selectedRows && selectedRows.length)) {
      let systemSatatus = getSystemStatus(selectedSystem);
      if (systemSatatus) {
        const selectedReportIds = selectedRows.map((item: any) => {
          return item?.systemPanelInspectionReportId;
        });
        const body = {
          SytemPanelInspectionReportIds: selectedReportIds,
        };
        dispatch(checkIsInspectionTaskCompleted(selectedSystem?.id, body,setIsDelete));
      } else {
        setMessage("Selected System is closed, cannot delete Final Report");
        setDisplayInfoModal(true);
      }
    } else {
      setMessage("Select an Inspection Report");
      setDisplayInfoModal(true);
    }
  }

  const removeReports = () => {
    setDisplayConfirmModal(false);
    const selectedReportIds = selectedRows.map((item: any) => {
      return item?.systemPanelInspectionReportId;
    });
    const body = {
      SytemPanelInspectionReportIds: selectedReportIds,
    };
    dispatch(onRemoveReports(selectedSystem?.id, body));
  }

  const onPageChanged = (options: any) => {
    if (options !== pageIndex) {
      let start = (options * pageDetails?.currentLength) + 1
      setPageIndex(options);
      setCurrentStart(start);
      if (selectedSystem?.id !== "") {
        dispatch(getReportList(selectedSystem?.id, start))
      }
    }
  }

  const viewDraftReport = (selectedRows) => {
    if (selectedRows?.length === 1) {
      setReportType("Draft");
      dispatch(onViewDraftReport(selectedSystem?.id, selectedRows[0]?.systemPanelInspectionReportId, setStreamData, setShowViewModal, setFileName, setMessage, setDisplayInfoModal))
    } else if (selectedRows?.length > 1) {
      setMessage('This operation can be performed only one row at a time.');
      setDisplayInfoModal(true);
      return;
    }
  };

  const viewFinalReport = (selectedRows) => {
    let reportId: any = [];
    if (selectedRows?.length === 1) {
      if (selectedRows[0]?.dateAndTimeUploaded) {
        reportId.push(selectedRows[0]?.systemPanelInspectionReportId);
        setReportType("Final");
        dispatch(onViewFinalReport(selectedSystem?.id, reportId, setStreamData, setShowViewModal, setFileName, setMessage, setDisplayInfoModal))
      } else {
        setMessage('Final Report not uploaded');
        setDisplayInfoModal(true);
      }
    } else {
      setMessage('This operation can be performed only one row at a time.');
      setDisplayInfoModal(true);
      return;
    }
  };

  const downloadDraftReport = () => {
    if (selectedRows.length < 1) {
      setMessage("Select an Inspection Report");
      setDisplayInfoModal(true);
    } else {
      let reportIds: any = [];
      selectedRows.forEach(element => {
        reportIds.push(element?.systemPanelInspectionReportId);
      });
      dispatch(onDownloadDraftReport(selectedSystem?.id, reportIds, setMessage, setDisplayInfoModal));
    }
  }

  const downloadFinalReport = () => {
    if (selectedRows.length < 1) {
      setMessage("Select an Inspection Report");
      setDisplayInfoModal(true);
    } else {
      let hasUploadedDate: boolean = false;
      let reportIds: any = [];
      selectedRows.forEach(element => {
        if (element?.dateAndTimeUploaded && element?.dateAndTimeUploaded !== "" && element?.dateAndTimeUploaded !== null) {
          hasUploadedDate = true
          reportIds.push(element?.systemPanelInspectionReportId);
        }
      });
      if (hasUploadedDate) {
        dispatch(onDownloadFinalReport(selectedSystem?.id, reportIds, setMessage, setDisplayInfoModal));
      }
      else {
        setMessage('Final Report not uploaded');
        setDisplayInfoModal(true);
      }
    }
  };

  const onAttachmentsClick = () => {
    if (selectedRows && selectedRows.length) {
      if (selectedRows.length === 1) {
        let systemSatatus = getSystemStatus(selectedSystem);
        if (systemSatatus) {
          setShowUploadXMLModal(true);
        } else {
          setMessage("Selected System is closed, cannot upload Final Report");
          setDisplayInfoModal(true);
        }
      } else {
        setMessage("This operation can be performed only one row at a time");
        setDisplayInfoModal(true);
      }
    } else {
      setMessage("Select an Inspection Report");
      setDisplayInfoModal(true);
    }
  };

  const onUploadFile = (data: any, setLoader: any) => {
    dispatch(uploadFinalReport(
      selectedSystem?.id, 
      selectedRows[0], 
      data, 
      setShowUploadXMLModal, 
      setMessage, 
      setDisplayInfoModal, 
      setSelectedRows,
      setLoader
    ));
  };

  const onSort = (event) => {
    let sortDirectionValue: string;
    if (event.sortOrder === 1) sortDirectionValue = "ASC";
    else sortDirectionValue = "DESC";
    dispatch(getReportList(selectedSystem?.id, currentStart, event?.sortField, sortDirectionValue));

  };

  const reportHeaderTemplate = (
    <div
      className={classNames("d-flex  justify-content-between", {
        "align-items-center justify-content-between": false,
      })}
    >
      {
        <div className="search-section d-flex align-items-center">
          <div className="attributes-dropdowns d-flex align-items-center">
            {systemHeaderDropdowns(systemLookup, "System")}
          </div>
        </div>
      }

      <div
        className={classNames("table-config", {
          "custom-align": false,
        })}
      >
        <div className="box-btn-wrapper">
        {showUI(userPrivileges, 'SIETAP_FS_VIEW_DRAFT_REPORTS') &&
          <Button className="box-btn" onClick={() => viewDraftReport(selectedRows)} disabled={reportList?.length ? false : true}>View Draft Report</Button> }
         {showUI(userPrivileges, 'SIETAP_FS_DOWNLOAD_DRAFT_REPORTS') &&
          <Button className="box-btn" disabled={reportList?.length ? false : true} onClick={downloadDraftReport}>Download Draft Report</Button> }
          <Button className="box-btn" onClick={() => viewFinalReport(selectedRows)} disabled={reportList?.length ? false : true}>View Report</Button>
          <Button className="box-btn" onClick={() => downloadFinalReport()} disabled={reportList?.length ? false : true}>Download Report</Button>
        </div>

        {(showUI(userPrivileges, 'SIETAP_FS_VIEW_DRAFT_REPORTS') || (showUI(userPrivileges, 'SIETAP_FS_DOWNLOAD_DRAFT_REPORTS'))) &&
        <div className="text-wrapper">
          <>
            <span> Drafted Report </span>
            <span> Final Report </span>
          </>
        </div>
}

        {(showUI(userPrivileges, 'SIETAP_FS_DELETE_DRAFT_REPORTS') || (showUI(userPrivileges, 'SIETAP_FS_UPLOAD_REPORTS'))) &&
          <div className="action-btns">
            {showUI(userPrivileges, 'SIETAP_FS_DELETE_DRAFT_REPORTS') &&
              <Button
                className="button-icon"
                tooltip="Delete"
                disabled={reportList?.length ? false : true}
                onClick={deleteReports}
                tooltipOptions={{ position: "top" }}
              >
                <BsTrashFill className="icon" />
              </Button>
            }
            {showUI(userPrivileges, 'SIETAP_FS_UPLOAD_REPORTS') &&
              <Button
                className="button-icon"
                tooltip="Upload Reports"
                disabled={reportList?.length ? false : true}
                onClick={onAttachmentsClick}
                tooltipOptions={{ position: "top" }}
              >
                <BsPaperclip className="icon" />
              </Button>
            }
          </div>
        }
      </div>
    </div>
  );

  return (
    <>
      <div className="reports-wrapper">
        <section className={`${filterExpanded ? "expanded" : ""} system-table`}>
          {showSystemGrid ? (
            <DataTableComponent
              rows={reportList}
              cols={REPORTS_COLS}
              title="Reports"
              dataKeyId="systemPanelInspectionReportId"
              paginator
              pageDetails={pageDetails}
              onPageChanged={onPageChanged}
              pageIndex={pageIndex}
              getSelectedRowData={handleRowSelect}
              headerTemplate={reportHeaderTemplate}
              isSelectionFromParent={true}
              selectedParentRows={selectedRows}
              onSortData={onSort}
            />
          ) : null}
          <InformationBox
            showInfoModal={displayInfoModal}
            setShowInfoModal={setDisplayInfoModal}
            message={message}
          />
          <ConfirmationBox
            showConfirmModal={displayConfirmModal}
            setShowConfirmModal={setDisplayConfirmModal}
            confirmAction={removeReports}
            message={message}
          />
          {showUploadXMLModal &&
            <UploadFinalReport
              showModal={showUploadXMLModal}
              setShowModal={setShowUploadXMLModal}
              onUploadFinalReportClick={onUploadFile}
              errorMessage="Select a PDF"
              title="Upload Report"
              acceptedFileTypes={acceptedFileTypes} />}
        </section>

        {showViewModal &&
          <DocumentViewer
            showModal={showViewModal}
            setShowModal={setShowViewModal}
            streamData={streamData}
            setStreamData={setStreamData}
            fileName={fileName}
            setFileName={setFileName}
            uniqeSystemId={selectedSystem?.id}
            systemInspectionReportId={selectedRows[0]?.systemPanelInspectionReportId}
            reportType={reportType}
            setReportType={setReportType} />
        }
      </div>
    </>
  );
};

export default Reports;