import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';

import { BsExclamationCircleFill } from 'react-icons/bs';

import SelectSalesOffice from './AddSelectSalesOffice'
import AddModal from 'components/Table/AddModal/AddModal';
//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks"
import { getSelectedSalesOffices } from "redux/pages/Manage/OrganizationSettings/salesOfficeSlice";
import { checkEmailAvailable, checkLoginNameAvailable, getUserRoles, setLoginAvailable } from 'redux/pages/Administration/User/userSlice';
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import moment from 'moment';

interface Props {
    showModal: boolean,
    setShowModal: (state: boolean) => void
    userRoleId?: number
    onAddUserClick: (data: any) => void,
}

type FormData = {
    loginName: string,
    resetPasswordOnNextLogin: boolean,
    firstName: string,
    middleName: string,
    lastName: string,
    userRole: any,
    accountActivationDate: any,
    accountExpiryDate: any,
    email: string,
    status: string,
    salesOffices: any,
};

export const AddUser: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    userRoleId = 1,
    onAddUserClick = () => { },
}) => {

    const addUserForm: any = useRef();

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            loginName: '',
            resetPasswordOnNextLogin: false,
            firstName: '',
            middleName: '',
            lastName: '',
            userRole: null,
            accountActivationDate: null,
            accountExpiryDate: null,
            email: '',
            status: "Active",
            salesOffices: [],
        }
    });

    const existingLogin = useAppSelector((state) => state.user.existingLogin);
    const existingEmail = useAppSelector((state) => state.user.existingEmail);
    const userRoles = useAppSelector(
        (state) => state.user.userRoles
    );
    const salesOfficeIds = useAppSelector((state) => state.salesOffice?.selectedSalesOffices);
    const loginName = watch("loginName");
    const firstName = watch("firstName");
    const lastName = watch("lastName");

    const [userRoleLookup, setUserRoleLookup] = useState([{ name: "", code: "" }]);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [showSelectSalesOffice, setShowSelectSalesOffice] = useState<boolean>(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [submitData, setSubmitData] = useState<any>();
    const [showSalesOffice, setShowSalesOffice] = useState(false);
    const [loginAsEmail, setLoginAsEmail] = useState(false);
    const [selectedSalesOffices, setSelectedSalesOffices] = useState<any>([]);

    const dispatch = useDispatch<any>();

    const setDate = useCallback(() => {
        let todayDate = new Date()
        let day = todayDate.getDate();
        let month = todayDate.getMonth();
        let year = todayDate.getFullYear();
        setValue("accountActivationDate", new Date(year, month, day));
        setValue("accountExpiryDate", new Date(year + 1, month, day));
        setValue("resetPasswordOnNextLogin", true);
    }, [setValue])

    useEffect(() => {
        setDate()
        dispatch(getUserRoles());
        switch (userRoleId) {
            case 1:
                setShowSalesOffice(false);
                setLoginAsEmail(false);
                break;
            case 2:
                setShowSalesOffice(false);
                setLoginAsEmail(true);
                break;
            case 3:
            case 4:
            case 5:
            case 6:
                setShowSalesOffice(true);
                setLoginAsEmail(true);
                break;
            default:
                setShowSalesOffice(false);
                setLoginAsEmail(false);
                break;
        }
    }, [dispatch, setDate])

    useEffect(() => {
        if (userRoles) {
            let userRole: { name: string, code: string }[] = [];
            userRoles.map((item: any) => {
                return userRole.push({ name: item.userRoleName, code: item.userRoleId });
            })
            setUserRoleLookup(userRole)
            let currentUserRole = userRole.find((item: any) => item.code === userRoleId);
            setValue("userRole", currentUserRole?.name)
        }
    }, [userRoles, setValue, userRoleId])

    useEffect(() => {
        if (salesOfficeIds && salesOfficeIds.length) {
            setSelectedSalesOffices(salesOfficeIds);
        }
    }, [salesOfficeIds]);

    const formSubmitHandler = (data: any) => {
        addUserForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
    };

    const onAddFormSubmit = (data: any) => {
        if (userRoleId !== 1 && userRoleId !== 2 && salesOfficeIds?.length === 0) {
            setDisplayInfoModal(true)
            setMessage("At least one Sales Office should be selected")
        } else if (new Date(data.accountActivationDate) > new Date(data.accountExpiryDate)) {
            setDisplayInfoModal(true)
            setMessage("'Account Activates on' must be earlier than 'Account Expires on'")
        } else {
            setSubmitData(data);
            setIsSubmit(true)
            let body = {
                "email": data.email
            }
            dispatch(checkEmailAvailable(body))
        }
    };

    useEffect(() => {
        if (isSubmit && existingEmail !== "") {
            if (existingEmail?.isEmailInUse) {
                setDisplayInfoModal(true)
                setMessage("Email already used by another Tech Advance + user")
            } else {
                if (userRoleId !== 1)
                    submitData.email = submitData.loginName?.trim();
                else
                    submitData.email = submitData.email?.trim();  

                let offices = getSalesOfficeIds(salesOfficeIds)
                submitData.salesOffices = offices;
                submitData.accountActivationDate = moment(submitData?.accountActivationDate).format("yyyy-MM-DD").toString()
                submitData.accountExpiryDate = moment(submitData?.accountExpiryDate).format("yyyy-MM-DD").toString()
                submitData.loginName=submitData.loginName?.trim();
                submitData.firstName= submitData.firstName?.trim();
                submitData.middleName=submitData.middleName?.trim();
                submitData.lastName= submitData.lastName?.trim();
                onAddUserClick && onAddUserClick(submitData)
            }
            setIsSubmit(false)
        }
    }, [existingEmail])

    const getSalesOfficeIds = (salesOfficeIds: any) => {
        let officeIds: any = [];
        salesOfficeIds.map((item: any) => {
            officeIds.push(item.uniqueSalesOfficeId);
            return null;
        });
        return officeIds;
    }

    const onCheckAvalibilty = (event) => {
        event?.preventDefault();
        if (loginName?.trim() !== "") {
            if (userRoleId !== 1 && !isEmailValid(loginName)) {
                setDisplayInfoModal(true)
                setMessage("Enter Login Name with valid Mail Domain")
            } else {
                let body = {
                    "loginName": loginName
                }
                dispatch(checkLoginNameAvailable(body, setMessage, setDisplayInfoModal))
            }
        } else {
            setDisplayInfoModal(true)
            setMessage("Enter Login Name")
        }
    }
    const isEmailValid = (email) => {
        if (!email) {
          return true;
        }
        return /^\s*\S+@\S+\.\S+\s*$/.test(email);
      };

    useEffect(() => {
        if (existingLogin !== "") {
            setDisplayInfoModal(true)
            setMessage("Login Name already used by another Tech Advance + user")
            dispatch(setLoginAvailable(""));
        }
    }, [existingLogin])

    const onSalesOfficeClick = (e: any) => {
        e.preventDefault()
        if (loginName === "") {
            setDisplayInfoModal(true)
            setMessage("Enter Login Name")
        } else if (firstName === "") {
            setDisplayInfoModal(true)
            setMessage("Enter First Name")
        } else if (lastName === "") {
            setDisplayInfoModal(true)
            setMessage("Enter Last Name")
        } else {
            resetSelectedUsers();
            setShowSelectSalesOffice(true);
        }
    }

    const resetSelectedUsers = useCallback(() => {
        dispatch(getSelectedSalesOffices([]));
    }, []);

    useEffect(() => {
        return () => {
            resetSelectedUsers();
        };
    }, []);

    //handle form validation errors
    const onError = (errors: any, e: any) => { };

    return (
        <>
            <AddModal
                header='New User'
                showModal={showModal}
                isAdd={true}
                setShowModal={setShowModal}
                style={{ width: '35vw', maxHeight: '100%' }}
                OnSaveClick={formSubmitHandler}
            >
                <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addUserForm}>
                    <div className="row px-2 pt-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="loginName"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        pattern: loginAsEmail ? { value: /^\s*\S+@\S+\.\S+\s*$/, message: 'Enter a valid Email as Login Name' } : undefined,
                                        validate: (value) => {
                                            return value.trim().length<=0
                                              ?"This field is required":true
                                          },
                                    }}

                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="loginName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Login Name</label>
                                {getFormErrorMessage('loginName')}
                            </span>
                        </div>
                    </div>

                    <div className='row px-2'>
                        <div className="field col-12 md:col-2 py-0 text-right m-0">
                            <Button label="Check Availability" aria-label="Check Availability" className='check-btnLink' onClick={onCheckAvalibilty} />
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-4 pt-0">
                            <div className="field-checkbox pt-2">
                                <Controller
                                    name="resetPasswordOnNextLogin"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            inputId="binary"
                                            disabled
                                            checked={field.value}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="binary">Change Password on next login</label>
                            </div>
                        </div>
                    </div>

                    <div className='row px-2'>
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="firstName"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        pattern:{value:/^([^0-9]*)$/,message:"Numeric characters are not allowed in First Name"},
                                        validate: (value) => {
                                            return value.trim().length<=0
                                              ?"This field is required":true
                                          },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="firstName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">First Name</label>
                                {getFormErrorMessage('firstName')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="middleName"
                                    control={control}
                                    rules={{
                                        pattern:{value:/^([^0-9]*)$/,message:"Numeric characters are not allowed in Middle Name"},
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="middleName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label htmlFor="inputtext">Middle Name</label>
                                {getFormErrorMessage('middleName')}
                            </span>
                        </div>
                    </div>


                    <div className='row px-2'>
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="lastName"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        pattern:{value:/^([^0-9]*)$/,message:"Numeric characters are not allowed in Last Name"},
                                        validate: (value) => {
                                            return value.trim().length<=0
                                              ?"This field is required":true
                                          },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="lastName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Last Name</label>
                                {getFormErrorMessage('lastName')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="accountActivationDate"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Calendar
                                            id="accountActivationDate"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            dateFormat="yy-mm-dd"
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)} />
                                    )} />
                                <label className='mandatory' htmlFor="calendar">Account Activates on</label>
                                {getFormErrorMessage('accountActivationDate')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="accountExpiryDate"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        validate: () => {
                                            return getValues("accountExpiryDate") <= new Date() ? "Account Expiry Date must be a future date" : undefined;
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Calendar
                                            id="accountExpiryDate"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            dateFormat="yy-mm-dd"
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)} />
                                    )} />
                                <label className='mandatory' htmlFor="calendar">Account Expires on</label>
                                {getFormErrorMessage('accountExpiryDate')}
                            </span>
                        </div>
                    </div>

                    {!loginAsEmail && (<div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        pattern: { value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$\s*/i, message: 'Enter a valid Email' }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="email"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Email</label>
                                {getFormErrorMessage('email')}
                            </span>
                        </div>
                    </div>)}

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="userRole"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="userRole"
                                            disabled
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={userRoleLookup}
                                            optionLabel="name"
                                            optionValue="name"
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="dropdown">User Role</label>
                                {getFormErrorMessage('userRole')}
                            </span>
                        </div>
                    </div>
                    {showSalesOffice && (<div className='row px-2 pb-2'>
                        <div className="field col-12 md:col-2 py-0 text-left m-0">
                            <Button label="Select Sales Office" aria-label="Select Sales Office" className='check-btnLink'   onClick={(e:any) => onSalesOfficeClick(e)}/>
                        </div>
                    </div>)}

                </form>
            </AddModal>
            {showSelectSalesOffice ? (
          <SelectSalesOffice
            showModal={showSelectSalesOffice}
            setShowModal={setShowSelectSalesOffice}
            setMessage={setMessage}
            setDisplayInfoModal={setDisplayInfoModal}
            selectedSalesOffices={selectedSalesOffices}
          />
        ) : null}
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
             
        </>
    );
}

export default AddUser;