import React, { useState, useCallback, useEffect, useRef } from "react";
import PasswordPolicy from "../SecuritySettings/PasswordPolicy";
import MailDomains from "../SecuritySettings/MailDomains";
import { useNavigate, useLocation } from "react-router-dom";
import { SECURITY_SETTINGS_TYPE } from "constants/common";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import { BsArrowRight } from "react-icons/bs";
import { APP_ROUTES } from "constants/AppRoutes";

interface Props {}
export const Securitysettings: React.FC<Props> = () => {
  const SECURITYSETTINGS_LIST = [
    { name: "Password Policy", code: "PA", selected: false },
    { name: "Mail Domains", code: "MA", selected: false },
  ];

  const [showPasswordPolicy, setShowPasswordPolicy] = useState(false);
  const [showMailDomains, setShowMailDomains] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const showSubtitle = useRef("Password Policy");
  const navigate = useNavigate();

  const [securitysettingList, setsecuritysettingList] = useState<Array<any>>(
    SECURITYSETTINGS_LIST
  );
  let {
    state: { from },
  } = useLocation() as any;

  const handleRoleSelect = useCallback(
    (selectedItem) => {
      setSelectedItem(selectedItem.name);
      showSubtitle.current = selectedItem.name;
      const modifiedsiteList = securitysettingList.map((item) => {
        if (item.code === selectedItem.code) {
          item["selected"] = !item["selected"];
        } else {
          item["selected"] = false;
        }

        return item;
      });
      setsecuritysettingList(modifiedsiteList);
    },
    [securitysettingList]
  );
  useEffect(() => {
 
    let selectedTab = securitysettingList.find((item: any) => item.name === from)
    selectedTab.selected = true;
    switch (from) {
      case SECURITY_SETTINGS_TYPE.MAIL_DOMAINS: {
        setSelectedItem("Mail Domains");
        showSubtitle.current = "Mail Domains";
        break;
      }
      case SECURITY_SETTINGS_TYPE.PASSWORD_POLICY: {
        setSelectedItem("Password Policy");
        showSubtitle.current = "Password Policy";
        break;
      }
    }
  }, [from]);
  useEffect(() => {
    switch (selectedItem) {
      case "Password Policy":
        setShowPasswordPolicy(true);
        setShowMailDomains(false);
        break;
      case "Mail Domains":
        setShowPasswordPolicy(false);
        setShowMailDomains(true);
        break;
      default:
        break;
    }
  }, [selectedItem]);
  return (
    <div className="security-settings">
      <section className="d-flex align-items-center">
				<Breadcrumb title="Security Settings" classNames="organization-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
				<div className="breadcrumb-right-arrow ml-3 mr-3">
					<BsArrowRight className="icon" />
				</div>
				<div className="breadcrumb-sub-page-title">
					<span>{showSubtitle.current}</span>
				</div>
			</section>
    
    <div className="securitysettings-container  d-flex">
      <section className="securitysettings-leftsidebar d-flex flex-column">
        <div className="securitysettings-listname">
          <div className="main">
            <ul className="list-items">
              {securitysettingList.map((item) => (
                <li
                  key={item.code}
                  className={item.selected ? "active" : ""}
                  onClick={() => handleRoleSelect(item)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className="securitysettings-expanded pl-3 pt-3 pr-3">
        {showPasswordPolicy && <PasswordPolicy />}
        {showMailDomains && <MailDomains />}
      </section>
    </div>
    </div>
  );
};
export default Securitysettings;
