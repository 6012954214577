import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

import ReportViewer from "components/common/Report/ReportViewer";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { createReportPreviewSession, setReportPreviewSession } from "redux/pages/Administration/Site/siteSlice";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    reportFlag: string;
    selectedRows: any;
    sortByColumns: any;
    inspectionTasksSelected: any;
}

export const CompareInspectionTaskReport: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    reportFlag = '',
    selectedRows = [],
    sortByColumns = [],
    inspectionTasksSelected = []
}) => {

    const [reportId, setReportId] = useState<any>(null);
    const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
    const dispatch = useDispatch<any>();

    const getSelectedIds = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.equipmentId);
        });
        return params;
    };

    useEffect(() => {
        let params;
        if (reportFlag === 'all') {
            params = "";
        } else {
            params = getSelectedIds();
        }
        const reportParameters = {
            reportParameters: {
                oldUniqueInspectionTaskId: inspectionTasksSelected[0]?.uniqueInspectionTaskId,
                newUniqueInspectionTaskId: inspectionTasksSelected[1]?.uniqueInspectionTaskId,
                sortColumn: "",
                oldInspectionTaskName: inspectionTasksSelected[0]?.inspectionTaskName,
                newInspectionTaskName: inspectionTasksSelected[1]?.inspectionTaskName,
                selectedIds: params
            },
            reportType: "Inspection Comparison Report"
        };
        dispatch(createReportPreviewSession(reportParameters));
        
        return(()=> {
			dispatch(setReportPreviewSession(""));
		});
    }, []);

    useEffect(() => {
        if (reportPreviewSession) {
            setReportId(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession])

    return (
        <>
            <Dialog
                header="Report"
                visible={showModal}
                onHide={() => {
                    dispatch(setReportPreviewSession(""));
                    setShowModal(false);
                }}
                className="display-settings report selectsite"
                style={{ width: "90vw", height: "600px" }}
            >
                <div className="report-export-main d-flex mb-3">
                </div>
                <ReportViewer reportId={reportId} />
            </Dialog>
        </>
    );
};

export default CompareInspectionTaskReport;
