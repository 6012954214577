import React, { memo, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { DataTableComponent } from "components/Table/DataTable";
import { BarChart, Bar, Cell, XAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, LabelList, YAxis, Label } from "recharts";
import { PERFORMANCE_TABLE_COL } from "../../DashboardData";
import { customPieChartTooltip, customInspectionDetailsChartTooltip, customContractChartTooltip } from "../../utility";

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getInspectionDetails, getContractDetails, getSystemTypes } from "redux/pages/Dashboard/dashboardSlice";
import { AiOutlineExpandAlt, AiOutlineShrink } from "react-icons/ai";

interface PropType {
	defaultSite?: any;
}
const PerformaceIndicator: React.FC<PropType> = (props) => {

	const { defaultSite } = props;

	const colors = ['#009999', '#56C692', '#F4D03F', '#0090B2', '#6A6DB6', '#985397', '#AC3D67', '#FF8749', '#95B1B0', '#B47F2A'];
	const [key, setKey] = useState("graphView");
	const [pieChartData, setPieChartData] = useState<any>([]);
	const [tableData, setTableData] = useState<any>([]);
	const [minToggle, setMinToggle] = useState(true);

	const dispatch = useDispatch<any>();
	const inspectionDetails = useAppSelector((state) => state.dashboard.inspectionDetails);
	const contractDetails = useAppSelector((state) => state.dashboard.contractDetails);
	const systemTypes = useAppSelector((state) => state.dashboard.systemTypes);

	useEffect(() => {
		dispatch(getInspectionDetails());
		dispatch(getContractDetails());
		dispatch(getSystemTypes());
	}, [dispatch]);

	useEffect(() => {
		if (systemTypes && systemTypes.panelTypes) {
			let panelTypes = [...systemTypes.panelTypes];
			setTableData([...systemTypes.panelTypes]);
			if (panelTypes.length && panelTypes.length > 10) {
				panelTypes.length = 10;
				setPieChartData(panelTypes);
			} else {
				setPieChartData(panelTypes);
			}
		}
	}, [systemTypes]);

	const changeTab = (tab) => {
		if (tab !== key) {
			setKey(tab);
		}
	}

	const onSort = (event) => {
		if (tableData && tableData.length && (key === "tableView")) {
			if (event && event.sortField) {
				let sortDirection: string;
				if (event.sortOrder === 1) {
					sortDirection = "ASC";
				} else {
					sortDirection = "DESC";
				}
				let data = [...tableData];
				if (event.sortField === "panelTypeName") {
					const collator = new Intl.Collator("en", {
						numeric: true,
						sensitivity: "base"
					});
					if (sortDirection === "ASC") {
						data.sort((a, b) => collator.compare(a.panelTypeName, b.panelTypeName));
					} else if (sortDirection === "DESC") {
						data.sort((a, b) => collator.compare(b.panelTypeName, a.panelTypeName));
					}
				} else if (event.sortField === "usage") {
					if (sortDirection === "ASC") {
						data.sort((a, b) => a.usage - b.usage);
					} else if (sortDirection === "DESC") {
						data.sort((a, b) => b.usage - a.usage);
					}
				}
				setTableData(data);
			}
		}
	}

	const toggleHandler = () => {
		if (minToggle) {
			setMinToggle(false)
		} else {
			setMinToggle(true);
		}
	};

	return (
		<div className={minToggle?"performance-indicator":"performance-indicator inspectionactivity_expand "}>
			<div className="left">
				<div className="top">
					<div className="dashboard-charts__area">
						<div className="task-completion__wrapper">		
							<span className="icon" onClick={() => toggleHandler()}>
								{minToggle ? <AiOutlineExpandAlt /> : <AiOutlineShrink />}
							</span>				
							{(inspectionDetails && inspectionDetails.length) ?
								(<>
									<div className="title">
										<h6>90 Days Inspection Activity</h6>									
									</div>
									<div className="bar-chart">
										<ResponsiveContainer width="100%" height="100%">
											<BarChart
												data={inspectionDetails}
												barCategoryGap="10%"
												style={{ fontSize: '0.7rem'}}
											>
												<XAxis dataKey="name" fontWeight={"bold"} />
												<YAxis domain={[0, 150]} hide={true} />
												<Legend />
												<Bar dataKey="Active" fill="#1981BE" minPointSize={5}>
													<LabelList dataKey="activeCount" position={"top"} fill="black" angle={(-90)} textAnchor="middle" dy={-10} />
												</Bar>
												<Bar dataKey="Closed" fill="#95B1B0" minPointSize={5} >
													<LabelList dataKey="closeCount" position={"top"} fill="black" angle={(-90)} textAnchor="middle" dy={-10} />
												</Bar>
												<Bar dataKey="Completed" fill="#009999" minPointSize={5}>
													<LabelList dataKey="completedCount" position={"top"} fill="black" angle={(-90)} textAnchor="middle" dy={-10} />
												</Bar>
												<Bar dataKey="Inactive" fill="#FF856C" minPointSize={5}>
													<LabelList dataKey="inactiveCount" position={"top"} fill="black" angle={(-90)} textAnchor="middle" dy={-10} />
												</Bar>
											</BarChart>
										</ResponsiveContainer>
									</div>
								</>) : (<></>)
							}
						</div>
					</div>
				</div>
				
				<div className="bottom">
					<div className="contracts-percentage">
						{(contractDetails && contractDetails.length) ?
							(<>
								<div className="title">
									<h6>Percentage of Contract Qty using TAP</h6>
								</div>
								<ResponsiveContainer width="100%" height="100%">
									<BarChart data={contractDetails}
										barCategoryGap="10%"
										style={{ fontSize: '0.7rem'}}
									>
										<XAxis dataKey="name" fontWeight={"bold"} />
										<YAxis domain={[0, 180]} hide={true} />
										<Legend />
										<Bar dataKey="SAP Contracts (Opportunity)" fill="#009999" minPointSize={5} >
											<LabelList dataKey="Percent SAP Contracts (Opportunity)" position={"top"} fill="black" textAnchor="middle" angle={(-90)} dy={-18} />
										</Bar>
										<Bar dataKey="Inspection Contracts (Used)" fill="#7886C2" minPointSize={5} >
											<LabelList dataKey="Percent Inspection Contracts (Used)" position={"top"} fill="black" textAnchor="middle" angle={(-90)} dy={-18} />
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</>) : (<></>)
						}
					</div>
				</div>
			</div>
			<div className="right">
				<div className="pie-graph__wrapper">
					{(pieChartData && pieChartData.length) ?
						(<>
							<div className="title">
								<h6>Mix of Systems Inspected (Last 12 Months)</h6>
							</div>
							<div className="tab-area">
								<Tabs id="pie-tab" activeKey={key} onSelect={changeTab} mountOnEnter={true} unmountOnExit={false}>
									<Tab eventKey="graphView" title="Graph View">
										<div className="pie-chart">
											<ResponsiveContainer>
												<PieChart>
													<Legend layout="horizontal" verticalAlign="top" align="center" wrapperStyle={{ fontSize: "10px" }} />
													<Pie
														style={{ fontSize: '0.7rem'}}
														data={pieChartData}
														cx="50%"
														cy="50%"
														startAngle={360}
														endAngle={0}
														labelLine={true}
														fill="#8884d8"
														dataKey="usage"
														outerRadius={75}
														innerRadius={0}
														label={(props) => {
															const { index } = props;
															const { panelTypeName, usage } = pieChartData[index];
															return `${panelTypeName}: ${usage}%`;
														}}    >
														{pieChartData.map((entry, index) => (
															<Cell key={`cell-${index}`} name={entry.panelTypeName} fill={colors[index % colors.length]} />
														))}
													</Pie>
													<Tooltip content={customPieChartTooltip} />
												</PieChart>
											</ResponsiveContainer>
										</div>
									</Tab>
									<Tab eventKey="tableView" title="Table View">
										<div className="table-view-table h-100">
											<DataTableComponent
												title={``}
												rows={tableData}
												cols={PERFORMANCE_TABLE_COL}
												dataKeyId="uniquePanelTypeId"
												onSortData={onSort}
											/>
										</div>
									</Tab>
								</Tabs>
							</div>
						</>) : (<></>)
					}
				</div>
			</div>
		</div>
	);
};

export default memo(PerformaceIndicator);

