import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import { classNames } from "primereact/utils";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { getHealthClassificationList } from "redux/pages/Manage/Lookups/HealthClassification/healthClassificationSlice";
import { getReportClassificationList } from "redux/pages/Manage/Lookups/NFPAClassification/NFPAClassificationSlice";
import { getEquipmentTypeListBsedOnHealth } from "redux/pages/Manage/Lookups/NFPAClassification/NFPAClassificationSlice";
import { BsExclamationCircleFill } from "react-icons/bs";

import AddModal from "components/Table/AddModal/AddModal";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface IAddNFPAClassification {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onAddNFPAClassification: (submitData: any) => void;
  Country: string;
}

type FormData = {
  Country: string;
  NFPACode: string;
  ReportClassification: string;
  HealthClassification: string;
  NFPAClassification: string;
};

export const AddNFPAClassification: React.FC<IAddNFPAClassification> = ({
  showModal = false,
  setShowModal = () => {},
  onAddNFPAClassification = () => {},
  Country = "",
}) => {
  const addUserForm: any = useRef();
  const equipmentTypeList = useAppSelector(
    (state) => state.nfpaclassification.equipmentTypeList
  );
  const [equipmentTypeReviseList, setEquipmentTypeReviseList] = useState<any>(
    []
  );
  const [checkedEquipmentTypes, setCheckedEquipmentTypes] = useState<any>([]);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [healthClassificationLookup, setHealthClassificationLookup] = useState([
    { name: "", code: "" },
  ]);
  const healthClassificationList = useAppSelector(
    (state) => state.healthClassification.healthClassificationList
  );
  const [reportClassificationLookup, setReportClassificationLookup] = useState([
    { name: "", code: "" },
  ]);
  const reportClassificationList = useAppSelector(
    (state) => state.nfpaclassification.ReportClassifications
  );
  const dispatch = useDispatch<any>();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      Country: "",
      NFPACode: "",
      ReportClassification: "",
      HealthClassification: "",
      NFPAClassification: "",
    },
  });
  useEffect(() => {
    let countrySelected: any = {};
    countrySelected = { countryName: Country };
    dispatch(getHealthClassificationList(countrySelected, 0));
  }, [dispatch,Country]);

  useEffect(() => {
    if (healthClassificationList && healthClassificationList?.length) {
      let healthClassification: { name: string; code: string }[] = [];
      healthClassification.push({ name: "--Select--", code: "--Select--" });
      healthClassificationList?.map((item: any) => {
        return healthClassification.push({
          name: item.healthClassification,
          code: item.code,
        });
      });
      setHealthClassificationLookup(healthClassification);
      setValue("HealthClassification", healthClassification[0].name);
    }
  }, [healthClassificationList, setValue]);
  useEffect(() => {
    dispatch(getReportClassificationList());
  }, [dispatch]);
  useEffect(() => {
    if (reportClassificationList && reportClassificationList?.length) {
      let reportClassification: { name: string; code: string }[] = [];
      reportClassification.push({ name: "--Select--", code: "--Select--" });
      reportClassificationList?.map((item: any) => {
        return reportClassification.push({
          name: item.flsCircutsClassificationName,
          code: item.flsCircutsClassificationId,
        });
      });
      setReportClassificationLookup(reportClassification);
      setValue("ReportClassification", reportClassification[0].name);
    }
  }, [reportClassificationList, setValue]);
  const formSubmitHandler = (data: any) => {
    addUserForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };
  useEffect(() => {
    dispatch(getEquipmentTypeListBsedOnHealth());
  }, [dispatch]);

  useEffect(() => {
    let ReviseData: any = [];
    if (equipmentTypeList?.length > 0) {
      equipmentTypeList.map((item: any) => {
        ReviseData = [
          ...ReviseData,
          {
            equipmentId: item.uniqueEquipmentClassId,
            equipmentName: item.equipmentClassName,
            isChecked: false,
          },
        ];
        return null;
      });
      setEquipmentTypeReviseList(ReviseData);
    }
  }, [equipmentTypeList]);

  const onEquipmentTypeChange = (e: { value: any; checked: boolean }) => {
    let EquipmentTypeListCopy = [...equipmentTypeReviseList];
    EquipmentTypeListCopy.map((item: any) => {
      if (e.value === item.equipmentName) {
        item.isChecked = !item.isChecked;
      }
      let checkedEquipmentTypeList = EquipmentTypeListCopy.filter(
        (el: any) => el.isChecked === true
      );
      setCheckedEquipmentTypes(checkedEquipmentTypeList);
      setEquipmentTypeReviseList(EquipmentTypeListCopy);
      return null;
    });
  };
  const onAddFormSubmit = (submitData: any) => {
    let checkedEquipmentTypeList: any = [];
    checkedEquipmentTypes.map((item: any) => {
      checkedEquipmentTypeList.push(item.equipmentId);
      return null;
    });
    if (checkedEquipmentTypeList.length === 0) {
      setMessage("Select an Equipment Type")
      setDisplayInfoModal(true);
      return;
    }
    let HealthClassId = "";
    healthClassificationList.map((healthClassItem: any, key: any) => {
      if (
        submitData.HealthClassification === healthClassItem.healthClassification
      ) {
        HealthClassId = healthClassItem.uniqueHealthClassificationId;
      }
      return null;
    });
    let ReportClassId = "";
    reportClassificationList.map((reportClassItem: any, key: any) => {
      if (
        submitData.ReportClassification ===
        reportClassItem.flsCircutsClassificationName
      ) {
        ReportClassId = reportClassItem.flsCircutsClassificationId;
      }
      return null;
    });
    let ReviseData = 
      {
        NFPACode: submitData.NFPACode.trim(),
        reportClassificationId: ReportClassId,
        NFPAClassificationName: submitData.NFPAClassification.trim(),
        uniqueHealthClassificationId: HealthClassId,
        EquipmentTypeIds: checkedEquipmentTypeList,
      }
    ;
    onAddNFPAClassification && onAddNFPAClassification(ReviseData);
  };

  const onError = (errors: any, e: any) => {};

  const onHealthClassificationChange=(value)=>{
    setCheckedEquipmentTypes([]);
    let uniqueHealthClassificationId;
       if(value === "--Select--")
          value = "";
       else{
          healthClassificationList.map((healthClassItem: any, key: any) => {
          if (value === healthClassItem.healthClassification) {
            uniqueHealthClassificationId = healthClassItem.uniqueHealthClassificationId;}
            return null;
        });
      }
      dispatch(getEquipmentTypeListBsedOnHealth(uniqueHealthClassificationId));     
  }

  return (
    <>
      <AddModal
        header="New NFPA Classification"
        showModal={showModal}
        isAdd={true}
        setShowModal={setShowModal}
        style={{ width: "35vw", maxHeight: "100%" }}
        OnSaveClick={formSubmitHandler}
      >
        <form
          onSubmit={handleSubmit(onAddFormSubmit, onError)}
          ref={addUserForm}
        >
          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Country</label>
                <Controller
                  name="Country"
                  control={control}
                  render={() => <span className="text-value">{Country}</span>}
                />
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="NFPACode"
                  control={control}
                  rules={{
                    required: "NFPA Code is required.",
                    maxLength: {
                      value : 10,
                      message : "Maximum characters exceeded"
                    },
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"NFPA Code is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="NFPA Code"
                      maxLength={10}
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  NFPA Code
                </label>
                {getFormErrorMessage("NFPACode")}
              </span>
            </div>
          </div>

          {/* <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                <Controller
                  name="ReportClassification"
                  control={control}
                  rules={{
                    required: "Report Classification is required.",
                    validate:(value)=>{
                      return value==="--Select--"?"Select a Report Classification":undefined;
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId=""
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={reportClassificationLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label className="mandatory" htmlFor="Country">
                  Report Classification
                </label>
                {getFormErrorMessage("ReportClassification")}
              </span>
            </div>
          </div> */}

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="NFPAClassification"
                  control={control}
                  rules={{
                    required: "NFPA Classification is required.",
                    maxLength: {
                      value : 100,
                      message : "Maximum 100 characters allowed"
                    },
                    
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"NFPA Classification is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="NFPAClassification"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  NFPA Classification
                </label>
                {getFormErrorMessage("NFPAClassification")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                <Controller
                  name="HealthClassification"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId=""
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => { field.onChange(e.value);onHealthClassificationChange(e.value)}}
                      options={healthClassificationLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label  htmlFor="Country">
                  Health Classification
                </label>
              </span>
            </div>
          </div>

          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-4">
              <div className="checkbox-list">
                <div className="p-float-label">
                  <label
                    className="mandatory checkbox-list-label"
                    htmlFor="checkbox"
                  >
                    Equipment Type
                  </label>
                </div>
                <ScrollPanel
                  style={{ width: "100%", height: "150px" }}
                  className="checkbox-list-scrollbar"
                >
                  {equipmentTypeReviseList && equipmentTypeReviseList.length
                    ? equipmentTypeReviseList.map((item: any, key: any) => (
                        <div className="field-checkbox px-2 pt-2" key={key}>
                          <Checkbox
                            inputId="binary"
                            id={item.equipmentId}
                            name={item.equipmentName}
                            value={item.equipmentName}
                            onChange={onEquipmentTypeChange}
                            checked={item.isChecked}
                          />
                          <label htmlFor="report1">{item.equipmentName}</label>
                        </div>
                      ))
                    : null}
                </ScrollPanel>
              </div>
            </div>
          </div>
        </form>
      </AddModal>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </>
  );
};

export default AddNFPAClassification;
