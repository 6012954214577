import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BsExclamationCircleFill } from 'react-icons/bs';
import AddModal from 'components/Table/AddModal/AddModal';
import { useAppSelector } from 'redux/hooks';
import { getSalesOfficesOfUser, getUserDetails, getUserRoles,checkUserContainInspectionTask,setUserContainInspectionTask  } from 'redux/pages/Administration/User/userSlice';
import { useDispatch } from 'react-redux';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import moment from 'moment';
import { getStatusList } from 'redux/pages/Administration/administrationSlice';
import { getSelectedSalesOffices } from "redux/pages/Manage/OrganizationSettings/salesOfficeSlice";
import SelectSalesOffice from './AddSelectSalesOffice';
import ConfirmationBox from 'components/common/Message/ConfirmationBox/ConfirmationBox';

interface Props {
    showModal: boolean,
    setShowModal: (state: boolean) => void,
    userRoleId?: number,
    selectedRows: any,
    onEditUserClick: (data: any) => void,
}

type FormData = {
    loginName: string,
    resetPasswordOnNextLogin: boolean,
    firstName: string,
    middleName: string,
    lastName: string,
    userRole: any,
    accountActivationDate: any,
    accountExpiryDate: any,
    email: string,
    status: string,
    salesOffices: any,
};

export const EditUser: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    userRoleId = 1,
    selectedRows = [],
    onEditUserClick = () => { },

}) => {
    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            loginName: '',
            resetPasswordOnNextLogin: false,
            firstName: '',
            middleName: '',
            lastName: '',
            userRole: null,
            accountActivationDate: null,
            accountExpiryDate: null,
            email: '',
            status: '',
            salesOffices: []
        }
    });

    const userRoles = useAppSelector((state) => state.user.userRoles);
    const userDetails = useAppSelector((state) => state.user.userDetails);
    const statusList = useAppSelector((state) => state.administration.statusList);
    const salesOfficeIds = useAppSelector((state) => state.salesOffice?.selectedSalesOffices);
    const userContainInspectionTask = useAppSelector((state) => state.user.userContainInspectionTask);
    const [showEditSelectSalesOffice, setShowEditSelectSalesOffice] = useState<boolean>(false);
    const [isCheckCompleted, setIsCheckCompleted] = useState<boolean>(false);
    const [userRoleLookup, setUserRoleLookup] = useState([{ name: "", code: "" }]);
    const [statusLookup, setStatusLookup] = useState([{ name: "", key: "" }]);
    const [status, setStatus] = useState<any>([]);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [showSalesOffice, setShowSalesOffice] = useState(false);
    const [loginAsEmail, setLoginAsEmail] = useState(false);
    const [selectedSalesOffices, setSelectedSalesOffices] = useState<any>([]);
    const [userSelected, setUserSelected] = useState<any>({});
    const [selectedUser, setSelectedUser] = useState<any>({});
    const [displayConfirmModal, setDisplayConfirmModal] = useState<boolean>(false);
    const editUserForm: any = useRef();
    const firstName = watch("firstName");
    const lastName = watch("lastName");
    const dispatch = useDispatch<any>();

    useEffect(() => {
        switch (userRoleId) {
            case 1:
                setShowSalesOffice(false);
                setLoginAsEmail(false);
                break;
            case 2:
                setShowSalesOffice(false);
                setLoginAsEmail(true);
                break;
            case 3:
            case 4:
            case 5:
            case 6:
                setShowSalesOffice(true);
                setLoginAsEmail(true);
                break;
            default:
                setShowSalesOffice(false);
                setLoginAsEmail(false);
                break;
        }
        setUserSelected(selectedRows[0]);
        setValue("accountActivationDate", new Date(selectedRows[0]?.accountActivationDate?.replace(/-/g, '\/')))
        setValue("accountExpiryDate",  new Date(selectedRows[0]?.accountExpiryDate?.replace(/-/g, '\/')))
        dispatch(getUserRoles());
        dispatch(getStatusList())
        dispatch(getUserDetails(selectedRows[0].uniqueUserId))
        dispatch(getSalesOfficesOfUser(selectedRows[0]?.uniqueUserId, setSelectedSalesOffices));
    }, [dispatch, selectedRows])

    useEffect(() => {
        if (userDetails !== "" && statusList?.status?.length > 0) {
            setValue("loginName", userDetails?.loginName)
            setValue("resetPasswordOnNextLogin", userDetails?.resetPasswordOnNextLogin)
            setValue("firstName", userDetails?.firstName)
            setValue("middleName", userDetails?.middleName ? userDetails?.middleName : "")
            setValue("lastName", userDetails?.lastName)
            setValue("status", userDetails?.status)
            setValue("email", userDetails?.email)
            let status = statusList?.status.find((item: any) => item.statusName === userDetails?.status)
            setStatus({ name: status.statusName, key: status.statusCode })
        }
    }, [userDetails, setValue, statusList])

    useEffect(() => {
        if (userRoles) {
            let userRole: { name: string, code: string }[] = [];
            userRoles.map((item: any) => {
                return userRole.push({ name: item.userRoleName, code: item.userRoleId });
            })
            setUserRoleLookup(userRole)
            let currentUserRole = userRole.find((item: any) => item.code === userRoleId);
            setValue("userRole", currentUserRole?.name)
        }
    }, [userRoles, setValue, userRoleId])

    useEffect(() => {
        if (statusList?.status && statusList?.status.length) {
            let status: { name: string, key: string }[] = [];
            statusList?.status.map((item: any) => {
                if (item.statusName === "Active" || item.statusName === "Blocked" || item.statusName === "Not Activated") {
                    status.push({ name: item.statusName, key: item.statusCode });
                }
                return status;
            })
            setStatusLookup(status)
        }

    }, [statusList])

    useEffect(() => {
        if (salesOfficeIds && salesOfficeIds.length) {
            setSelectedSalesOffices(salesOfficeIds);
        }
    }, [salesOfficeIds]);


    const formSubmitHandler = (data: any) => {
        editUserForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
    };

    const onEditFormSubmit = (data: any) => {
        if (userRoleId !== 1 && userRoleId !== 2 && selectedSalesOffices?.length === 0) {
            setDisplayInfoModal(true)
            setMessage("At least one Sales Office should be selected")
        } else if (new Date(data.accountActivationDate) > new Date(data.accountExpiryDate)) {
            setDisplayInfoModal(true)
            setMessage("'Account Activates on' must be earlier than 'Account Expires on'")
        } else {
            if((userRoleId > 2) && (selectedSalesOffices?.length) && status.name === 'Active')
            {
                let offices = getSalesOfficeIds(selectedSalesOffices)
                 setSelectedUser(data)
                let requestBody = {
                    salesofficeids: offices,
                    userroleId: data.userRoleId,
                    userIds: [userSelected.uniqueUserId],
                    isdelete:false
                }
                dispatch(checkUserContainInspectionTask(requestBody,setIsCheckCompleted));
               
            }
            else if(userRoleId > 1 && (status?.name === 'Not Activated' || status?.name === 'Blocked')){
                setSelectedUser(data)
                let requestBody = {
                    userroleId: selectedRows.userRoleId,
                    userIds: [userSelected.uniqueUserId],
                    isdelete: true
                  }
                  dispatch(checkUserContainInspectionTask(requestBody,setIsCheckCompleted));
            }
            else{
            if (userRoleId !== 1)
                data.email = data.loginName?.trim()
            let offices = getSalesOfficeIds(selectedSalesOffices)
            data.salesOffices = offices;
            data.status = status.name;
            data.accountActivationDate = moment(data?.accountActivationDate).format("yyyy-MM-DD ").toString()
            data.accountExpiryDate = moment(data?.accountExpiryDate).format("yyyy-MM-DD").toString()
            data.loginName=data.loginName?.trim();
            data.firstName= data.firstName?.trim();
            data.middleName=data.middleName?.trim();
            data.lastName= data.lastName?.trim();
            data.inspectionTaskIds = userContainInspectionTask?.inspectionTasksList;
            onEditUserClick && onEditUserClick(data)
           }
        }
    };
    useEffect(() => {
        if(isCheckCompleted){
        if(userContainInspectionTask.isUserInspectionTask && (status?.name === 'Blocked' || status?.name === 'Not Activated')){
            setMessage("Changing the Status will remove the Lead Technician from the associated inspection tasks. Do you want to proceed?");
            setDisplayConfirmModal(true);
        }
         else if (userContainInspectionTask.isUserInspectionTask && status?.name === 'Active') {
           setDisplayConfirmModal(true);
           setMessage("Changing the Sales Office will remove the Lead Technician from the associated inspection tasks. Do you want to proceed?");
        }
        else if(userContainInspectionTask.isUserInspectionTask === false) {
            if (userRoleId !== 1)
                selectedUser.email = selectedUser.loginName?.trim()
            let offices = getSalesOfficeIds(selectedSalesOffices)
            let ids = userContainInspectionTask?.inspectionTasksList
            selectedUser.salesOffices = offices;
            selectedUser.status = status.name;
            selectedUser.accountActivationDate = moment(selectedUser?.accountActivationDate).format("yyyy-MM-DD ").toString()
            selectedUser.accountExpiryDate = moment(selectedUser?.accountExpiryDate).format("yyyy-MM-DD").toString()
            selectedUser.loginName=selectedUser.loginName?.trim();
            selectedUser.firstName= selectedUser.firstName?.trim();
            selectedUser.middleName=selectedUser.middleName?.trim();
            selectedUser.lastName= selectedUser.lastName?.trim();
            selectedUser.inspectionTaskIds = userContainInspectionTask?.inspectionTasksList;
            onEditUserClick && onEditUserClick(selectedUser)
            setDisplayConfirmModal(false);
            setShowModal(false);
            dispatch(setUserContainInspectionTask(""))   
        }
        setIsCheckCompleted(false)
    }
    }, [isCheckCompleted]);

    const onConfirmAction = () => {
        if (userRoleId !== 1)
            selectedUser.email = selectedUser.loginName?.trim()
        let offices = getSalesOfficeIds(selectedSalesOffices)
        selectedUser.salesOffices = offices;
        selectedUser.status = status.name;
        selectedUser.accountActivationDate = moment(selectedUser?.accountActivationDate).format("yyyy-MM-DD ").toString()
        selectedUser.accountExpiryDate = moment(selectedUser?.accountExpiryDate).format("yyyy-MM-DD").toString()
        selectedUser.loginName=selectedUser.loginName?.trim();
        selectedUser.firstName= selectedUser.firstName?.trim();
        selectedUser.middleName=selectedUser.middleName?.trim();
        selectedUser.lastName= selectedUser.lastName?.trim();
        selectedUser.inspectionTaskIds = userContainInspectionTask?.inspectionTasksList;
        onEditUserClick && onEditUserClick(selectedUser)
            setDisplayConfirmModal(false);
            setShowModal(false);
            dispatch(setUserContainInspectionTask(""))
            
      };
    
    const onCancelAction = () => {
        dispatch(setUserContainInspectionTask(""))  
        setDisplayConfirmModal(false);
        setShowModal(false);
    };


    const getSalesOfficeIds = (salesOfficeIds: any) => {
        let officeIds: any = [];
        salesOfficeIds.map((item: any) => {
            officeIds.push(item.uniqueSalesOfficeId);
            return null;
        });
        return officeIds;
    }

    const onSalesOfficeClick = (e: any) => {
        e.preventDefault()
        if (firstName === "") {
            setDisplayInfoModal(true)
            setMessage("Enter First Name")
        } else if (lastName === "") {
            setDisplayInfoModal(true)
            setMessage("Enter Last Name")
        } else {
            resetSelectedUsers();
            setShowEditSelectSalesOffice(true);
        }
    }

    const resetSelectedUsers = useCallback(() => {
        dispatch(getSelectedSalesOffices([]));
    }, []);

    useEffect(() => {
        return () => {
            resetSelectedUsers();
        };
    }, []);

    return ( 
         <>

        <div>
            <AddModal
                header='Edit User'
                showModal={showModal}
                setShowModal={setShowModal}
                style={{ width: '35vw', maxHeight: '100%' }}
                OnSaveClick={formSubmitHandler}
                isAdd={false}
            >
                <form onSubmit={handleSubmit(onEditFormSubmit)} ref={editUserForm}>
                    <div className="row px-2 pt-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="loginName"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        pattern: loginAsEmail ? { value: /^\S+@\S+\.\S+$/, message: 'Enter a valid Email as Login Name' } : undefined,
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            disabled
                                            id="loginName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Login Name</label>
                                {getFormErrorMessage('loginName')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-4 pt-0">
                            <div className="field-checkbox pt-2">
                                <Controller
                                    name="resetPasswordOnNextLogin"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            inputId="binary"
                                            checked={field.value}
                                            {...field}
                                        />
                                    )} />
                                <label htmlFor="binary">Change Password on next login</label>
                            </div>
                        </div>
                    </div>

                    <div className='row px-2'>
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="firstName"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        pattern:{value:/^([^0-9]*)$/,message:"Numeric characters are not allowed in First Name"},
                                        validate: (value) => {
                                            return value.trim().length<=0
                                              ?"This field is required":true
                                          },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="firstName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">First Name</label>
                                {getFormErrorMessage('firstName')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="middleName"
                                    control={control}
                                    rules={{
                                        pattern:{value:/^([^0-9]*)$/,message:"Numeric characters are not allowed in Middle Name"},
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="middleName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label htmlFor="inputtext">Middle Name</label>
                                {getFormErrorMessage('firstName')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="lastName"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        pattern:{value:/^([^0-9]*)$/,message:"Numeric characters are not allowed in Last Name"},
                                        validate: (value) => {
                                            return value.trim().length<=0
                                              ?"This field is required":true
                                          },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="lastName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Last Name</label>
                                {getFormErrorMessage('lastName')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="accountActivationDate"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Calendar
                                            id="accountActivationDate"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            dateFormat="yy-mm-dd"
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)} />
                                    )} />
                                <label className='mandatory' htmlFor="calendar">Account Activates on</label>
                                {getFormErrorMessage('accountActivationDate')}
                            </span>
                        </div>
                    </div>

                    <div className='row px-2'>
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="accountExpiryDate"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        validate: () => {
                                            return getValues("accountExpiryDate") <= new Date() ? "Account Expiry Date must be a future date" : undefined;
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Calendar
                                            id="accountExpiryDate"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            dateFormat="yy-mm-dd"
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)} />
                                    )} />
                                <label className='mandatory' htmlFor="calendar">Account Expires on</label>
                                {getFormErrorMessage('accountExpiryDate')}
                            </span>
                        </div>
                    </div>

                    {!loginAsEmail && (<div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.',
                                        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Enter a valid Email' }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="email"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Email</label>
                                {getFormErrorMessage('email')}
                            </span>
                        </div>
                    </div>)}

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="userRole"
                                    control={control}
                                    rules={{
                                        required: 'This field is required.'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="userRole"
                                            disabled
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={userRoleLookup}
                                            optionLabel="name"
                                            optionValue="name"
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="dropdown">User Role</label>
                                {getFormErrorMessage('userRole')}
                            </span>
                        </div>
                    </div>
                  
                    <div className='row px-2'>
                        <div className="field col-12 md:col-2 mt-2">
                            <span className="p-float-label">
                                <label className='mandatory' htmlFor="dropdown" style={{ marginTop: '-12px', color: '#009999' }}>User Status</label>
                                {getFormErrorMessage('status')}
                            </span>
                            <div className='radio-section d-flex align-items-center pt-2 pl-2'>
                                {
                                    statusLookup.map((item) => {
                                        return (
                                            <div key={item.key} className="field-radiobutton mb-0 pr-4">
                                                <RadioButton inputId={item.key} name="status" value={item} onChange={(e) => setStatus(e.value)} checked={status.key === item.key} />
                                                <label htmlFor={item.key}>{item.name}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                   {showSalesOffice && ( <div className='row px-2 pb-2'>
                        <div className="field col-12 md:col-2 py-0 text-left m-0">
                            <Button label="Select Sales Office" aria-label="Select SalesOffice" className='check-btnLink'   onClick={(e:any) =>onSalesOfficeClick(e)}/>
                        </div>
                    </div>)}
                </form>
            </AddModal >
            {showEditSelectSalesOffice ? (
            <SelectSalesOffice
                showModal={showEditSelectSalesOffice}
                setShowModal={setShowEditSelectSalesOffice}
                setMessage={setMessage}
                setDisplayInfoModal={setDisplayInfoModal}
                selectedSalesOffices={selectedSalesOffices}
                userSelected={userSelected}
            />
        ) : null}  
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                cancelAction={() => onCancelAction()}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onConfirmAction}
                message={message}
            />
              
        </div>
        
        
        </>
    );
}

export default EditUser;