import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { getBuildingListLookup } from 'redux/pages/Administration/Floor/floorSlice';


interface Props {
  getTableColumns?: (data: any) => void,
};

export const SiteFloorTableData: React.FC<Props> = ({
  getTableColumns = () => { },
}) => {
  const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
  const buildngDetails = useAppSelector((state) => state.floor.buildingList);
  const [buildingLookup, setBuildingLookup] = useState<any>([]);

  const siteFloorColumns = useRef<any>();

  const dispatch = useDispatch<any>();

  useEffect(() => {
    let body = {
      uniqueSiteId: loggedInUserData?.userDefaults?.uniqueSiteId
    };
    dispatch(getBuildingListLookup(loggedInUserData?.userDefaults?.uniqueSiteId, body));
  }, []);

  useEffect(() => {
    if (buildngDetails) {
      let building: any = [];
      buildngDetails.map((item: any) => {
        building.push({ label: item.buildingName, value: item.uniqueBuildingId });
      })
      setBuildingLookup(building)
    }
  }, [buildngDetails]);

  const getErrorMsg = options => {
    const { field, value } = options;
    const FIELD_REQUIRED = 'This field is required';
    switch (field) {
      case 'buildingName': {
        if (!value.trim())
          return FIELD_REQUIRED;
        break;
      }
      case 'floorName': {
        if (!value.trim())
          return FIELD_REQUIRED;
        break;
      }
      default: {
        return ''
      }
    }
  };

  const buildingEditor = (options) => {
    return (
      <Dropdown value={options.value} options={buildingLookup} optionLabel="label" optionValue="label"
        onChange={(e) => options.editorCallback(e.value)} placeholder="Select Building"
        itemTemplate={(option) => {
          return <span className='role-badge'>{option.label}</span>
        }} />
    );
  }

  const textEditor = (options) => {
    const errorMsg = getErrorMsg(options);
    return <>
      <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
      {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
    </>
  }

  const disabledTextEditor = (options) => {
    return <InputText type="text" disabled value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const SITEFLOOR_COLS = [
    {
      field: 'siteName',
      header: 'Site',
      editor: (options) => disabledTextEditor(options),
      style: { flex: '1 0 200px' },
      sortable: true,
    },
    {
      field: 'buildingName',
      header: 'Building',
      editor: (options) => buildingEditor(options),
      style: { flex: '1 0 80px' },
      sortable: true,
    },
    {
      field: 'floorName',
      header: 'Floor',
      editor: (options) => textEditor(options),
      style: { flex: '1 0 80px' },
      sortable: true,
    },
    {
      field: 'attachmentCount',
      header: 'Attachments',
      eeditor: (options) => disabledTextEditor(options),
      style: { flex: '1 0 70px' },
      sortable: true,
      align:"right"
    },
    {
      field: 'statusName',
      header: 'Status',
      editor: (options) => disabledTextEditor(options),
      style: { flex: '1 0 40px' },
      sortable: true,
    }
  ];

  siteFloorColumns.current = SITEFLOOR_COLS;

  useEffect(() => {
    if (buildingLookup?.length >= 0)
    {
      getTableColumns(siteFloorColumns.current);
    }
  }, [getTableColumns,buildingLookup]);

  return (
    <>
    </>
  )
}
export default SiteFloorTableData;