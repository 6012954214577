import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";

import AddModal from "components/Table/AddModal/AddModal";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { getHealthClassificationDetails } from "redux/pages/Manage/Lookups/HealthClassification/healthClassificationSlice";
import { getEquipmentTypeList } from "redux/pages/Manage/Customization/Attributes/EquipmentTypeAttributeSlice";

interface IEditHealthClassification {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  Country: string;
  CountryId: string;
  onEditHealthClassification: (submitData: any) => void;
}

type FormData = {
  Country: string;
  HealthClassification: string;
};

export const EditHealthClassification: React.FC<IEditHealthClassification> = ({
  showModal = false,
  setShowModal = () => {},
  selectedRows = [],
  Country = "",
  CountryId = "",
  onEditHealthClassification = () => {},
}) => {
  const healthClassificationDetails = useAppSelector(
    (state) => state.healthClassification.healthClassificationDetails
  );
  const equipmentTypeList = useAppSelector(
    (state) => state.EquipmentTypeAttribute.equipmentTypeList
  );
  const [equipmentTypeReviseList, setEquipmentTypeReviseList] = useState<any>(
    []
  );
  const [checkedEquipmentTypes, setCheckedEquipmentTypes] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);

  const EditForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      Country: Country,
      HealthClassification: "",
    },
  });

  const formSubmitHandler = (data: any) => {
    EditForm.current.requestSubmit();
  };

  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(
      getHealthClassificationDetails(
        selectedRows[0].uniqueHealthClassificationId
      )
    );
  }, [dispatch, selectedRows]);

  useEffect(() => {
    dispatch(getEquipmentTypeList());
  }, [dispatch]);
  useEffect(() => {
    if (equipmentTypeReviseList) {
      equipmentTypeReviseList.map((item: any) => {
        setValue(item.equipmentName, item.equipmentName);
        return null;
      });
    }
  }, [equipmentTypeReviseList, setValue]);
  useEffect(() => {
    if (healthClassificationDetails !== "") {
      setValue("HealthClassification", selectedRows[0].healthClassification);
    }
  }, [healthClassificationDetails, selectedRows, setValue]);
  useEffect(() => {
    let ReviseData: any = [];
    if (equipmentTypeList?.length > 0) {
      equipmentTypeList.map((item: any) => {
        let checked = false;
        if (healthClassificationDetails.length !== 0) {
          healthClassificationDetails.equipmentClassDetails.map(
            (equipmentTypeItem: any) => {
              if (
                equipmentTypeItem.equipmentClassName === item.equipmentClassName
              ) {
                checked = true;
              }
              return null;
            }
          );
        }
        ReviseData = [
          ...ReviseData,
          {
            equipmentId: item.uniqueEquipmentClassId,
            equipmentName: item.equipmentClassName,
            isChecked: checked,
          },
        ];
        return null;
      });
      let checkedEquipmentTypeList = ReviseData.filter(
        (el: any) => el.isChecked === true
      );
      setCheckedEquipmentTypes(checkedEquipmentTypeList);
      setEquipmentTypeReviseList(ReviseData);
    }
  }, [equipmentTypeList, healthClassificationDetails]);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onEditFormSubmit = (submitData: any) => {
    let checkedEquipmentTypeList: any = [];
    checkedEquipmentTypes.map((item: any) => {
      checkedEquipmentTypeList.push(item.equipmentId);
      return null;
    });
    if (checkedEquipmentTypeList.length === 0) {
      setMessage("Select an Equipment Type")
      setDisplayInfoModal(true);
      return;
    }
    let ReviseData = 
      {
        healthClassificationName: submitData.HealthClassification.trim(),
        uniqueEquipmentClassIds: checkedEquipmentTypeList,
      }
    ;
    onEditHealthClassification && onEditHealthClassification(ReviseData);
  };
  const onEquipmentTypeChange = (e: { value: any; checked: boolean }) => {
    let EquipmentTypeListCopy = [...equipmentTypeReviseList];
    EquipmentTypeListCopy.map((item: any) => {
      if (e.value === item.equipmentName) {
        item.isChecked = !item.isChecked;
      }
      let checkedEquipmentTypeList = EquipmentTypeListCopy.filter(
        (el: any) => el.isChecked === true
      );
      setCheckedEquipmentTypes(checkedEquipmentTypeList);
      setEquipmentTypeReviseList(EquipmentTypeListCopy);
      return null;
    });
  };
  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="Edit Health Classification"
        showModal={showModal}
        isAdd={false}
        setShowModal={setShowModal}
        style={{ width: "35vw", maxHeight: "100%" }}
        OnSaveClick={formSubmitHandler}
      >
        <form onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={EditForm}>
          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Country</label>
                <Controller
                  name="Country"
                  control={control}
                  render={() => <span className="text-value">{Country}</span>}
                />
              </span>
            </div>
          </div>

          <div className="row px-2 mb-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="HealthClassification"
                  control={control}
                  rules={{
                    required: "Health Classification is required.",
                    maxLength: {
                      value : 100,
                      message : "Maximum 100 characters allowed"
                  },
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Health Classification is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="HealthClassification"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Health Classification
                </label>
                {getFormErrorMessage("HealthClassification")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-4">
              <div className="checkbox-list">
                <div className="p-float-label">
                  <label
                    className="mandatory checkbox-list-label"
                    htmlFor="checkbox"
                  >
                    Equipment Type
                  </label>
                </div>
                <ScrollPanel
                  style={{ width: "100%", height: "150px" }}
                  className="checkbox-list-scrollbar"
                >
                  {equipmentTypeReviseList && equipmentTypeReviseList.length
                    ? equipmentTypeReviseList.map((item: any, key: any) => (
                        <div className="field-checkbox px-2 pt-2" key={key}>
                          <Checkbox
                            inputId="binary"
                            id={item.equipmentName}
                            name={item.equipmentName}
                            value={item.equipmentName}
                            onChange={onEquipmentTypeChange}
                            checked={item.isChecked}
                          />
                          <label htmlFor="report1">{item.equipmentName}</label>
                        </div>
                      ))
                    : null}
                </ScrollPanel>
              </div>
            </div>
          </div>
        </form>
      </AddModal>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </>
  );
};

export default EditHealthClassification;
