import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import { classNames } from "primereact/utils";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { getHealthClassificationList } from "redux/pages/Manage/Lookups/HealthClassification/healthClassificationSlice";
import {
  getReportClassificationList,
  getNFPAClassificationDetails,
  getEquipmentTypeListBsedOnHealth,
  checkNFPAInUseForEdit,
  setInUseReturnForEdit
} from "redux/pages/Manage/Lookups/NFPAClassification/NFPAClassificationSlice";
import { BsExclamationCircleFill } from "react-icons/bs";

import AddModal from "components/Table/AddModal/AddModal";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";

interface IEditNFPAClassification {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  Country: string;
  CountryId: string;
  onEditNFPAClassification: (submitData: any) => void;
}

type FormData = {
  Country: string;
  NFPACode: string;
  ReportClassification: string;
  HealthClassification: string;
  NFPAClassification: string;
};

export const EditNFPAClassification: React.FC<IEditNFPAClassification> = ({
  showModal = false,
  setShowModal = () => {},
  selectedRows = [],
  Country = "",
  CountryId = "",
  onEditNFPAClassification = () => {},
}) => {
  const EditForm: any = useRef();
  const equipmentTypeList = useAppSelector(
    (state) => state.nfpaclassification.equipmentTypeList
  );
  const nfpaClassificationDetails = useAppSelector(
    (state) => state.nfpaclassification.NFPAClassificationDetails
  );
  const inUseReturn = useAppSelector(
    (state) => state.nfpaclassification.inUseReturn
  );
  const [equipmentTypeReviseList, setEquipmentTypeReviseList] = useState<any>(
    []
  );
  const [checkedEquipmentTypes, setCheckedEquipmentTypes] = useState<any>([]);

  const [healthClassificationLookup, setHealthClassificationLookup] = useState([
    { name: "", code: "" },
  ]);
  const healthClassificationList = useAppSelector(
    (state) => state.healthClassification.healthClassificationList
  );
  const [reportClassificationLookup, setReportClassificationLookup] = useState([
    { name: "", code: "" },
  ]);
  const reportClassificationList = useAppSelector(
    (state) => state.nfpaclassification.ReportClassifications
  );
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const [submitData, setSubmitData] = useState<any>("");
  const [selectedHealthClassification, setselectedHealthClassification] = useState<any>("");
  const dispatch = useDispatch<any>();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      Country: "",
      NFPACode: "",
      ReportClassification: "",
      HealthClassification: "",
      NFPAClassification: "",
    },
  });
  useEffect(() => {
    let countrySelected: any = {};
    countrySelected = { countryName: Country };
    dispatch(getHealthClassificationList(countrySelected, 0));
  }, [dispatch,Country]);
  useEffect(() => {
    if(selectedRows.length>0){
    dispatch(
      getNFPAClassificationDetails(
        CountryId,
        selectedRows[0]?.uniquenfpaClassificationId
      )
    );
      }
  }, [dispatch, selectedRows, CountryId]);
  useEffect(() => {
    if(selectedRows.length>0)
    {
    if (healthClassificationList && healthClassificationList?.length) {
      let healthClassification: { name: string; code: string }[] = [];
      healthClassification.push({ name: "--Select--", code: "--Select--" });
      healthClassificationList?.map((item: any) => {
        return healthClassification.push({
          name: item.healthClassification,
          code: item.code,
        });
      })
      setHealthClassificationLookup(healthClassification);
    }
  }
  }, [healthClassificationList, setValue,selectedRows]);
  useEffect(() => {
    dispatch(getReportClassificationList());
  }, [dispatch]);
  useEffect(() => {
    if (reportClassificationList && reportClassificationList?.length) {
      let reportClassification: { name: string; code: string }[] = [];
      reportClassification.push({ name: "--Select--", code: "--Select--" });
      reportClassificationList?.map((item: any) => {
        return reportClassification.push({
          name: item.flsCircutsClassificationName,
          code: item.flsCircutsClassificationId,
        });
      });
      setReportClassificationLookup(reportClassification);
    }
  }, [reportClassificationList, setValue,selectedRows]);
  const formSubmitHandler = (data: any) => {
    EditForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };
  useEffect(() => {
    if(nfpaClassificationDetails?.length !== 0)
     {
         let uniqueHealthcareClassificationId;
         if(nfpaClassificationDetails?.healthClassification?.uniqueHealthcareClassificationId === null){
             uniqueHealthcareClassificationId = "";
             setselectedHealthClassification(null)}
         else{
             uniqueHealthcareClassificationId = nfpaClassificationDetails?.healthClassification?.uniqueHealthcareClassificationId;
             setselectedHealthClassification(nfpaClassificationDetails.healthClassification.healthcareClassificationName)}

            dispatch(getEquipmentTypeListBsedOnHealth(uniqueHealthcareClassificationId));
     }
  }, [nfpaClassificationDetails]);

  useEffect(() => {
    if (equipmentTypeReviseList) {
      equipmentTypeReviseList.map((item: any) => {
        setValue(item.equipmentName, item.equipmentName);
        return null;
      });
    }
  }, [equipmentTypeReviseList, setValue]);
  useEffect(() => {
    if (nfpaClassificationDetails?.length !== 0) {
      setValue("ReportClassification", nfpaClassificationDetails.reportClassification);
      setValue("NFPACode", nfpaClassificationDetails.nfpaCode);
      setValue("HealthClassification", nfpaClassificationDetails.healthClassification.healthcareClassificationName);
      setValue("NFPAClassification", nfpaClassificationDetails.nfpaClassification);
    }
  }, [nfpaClassificationDetails, setValue]);
  useEffect(() => {
    let ReviseData: any = [];
    if (equipmentTypeList?.length > 0) {
      equipmentTypeList.map((item: any) => {
        let checked = false;
        if (nfpaClassificationDetails.length !== 0) {
          nfpaClassificationDetails.equipmentClassDetails.map(
            (equipmentTypeItem: any) => {
              if (equipmentTypeItem.equipmentClassName === item.equipmentClassName && selectedHealthClassification === nfpaClassificationDetails.healthClassification.healthcareClassificationName) {
                     checked = true;
              }
              return null;
            }
          );
        }
        ReviseData = [
          ...ReviseData,
          {
            equipmentId: item.uniqueEquipmentClassId,
            equipmentName: item.equipmentClassName,
            isChecked: checked,
          },
        ];
        return null;
      });
      let checkedEquipmentTypeList = ReviseData.filter(
        (el: any) => el.isChecked === true
      );
      setCheckedEquipmentTypes(checkedEquipmentTypeList);
      setEquipmentTypeReviseList(ReviseData);
    }
  }, [equipmentTypeList, nfpaClassificationDetails]);

  useEffect(() => {
    if (inUseReturn) {
      if(inUseReturn?.isNFPAClassificationInUse)
        {
          setMessage(inUseReturn?.returnMessage);
          setDisplayConfirmModal(true);

        }
        else{
          onEditNFPAClassification && onEditNFPAClassification(submitData);
          setSubmitData("");
          dispatch(setInUseReturnForEdit(""));
        }
      
    }
  },[inUseReturn])

  const onEquipmentTypeChange = (e: { value: any; checked: boolean }) => {
    let EquipmentTypeListCopy = [...equipmentTypeReviseList];
    EquipmentTypeListCopy.map((item: any) => {
      if (e.value === item.equipmentName) {
        item.isChecked = !item.isChecked;
      }
      let checkedEquipmentTypeList = EquipmentTypeListCopy.filter(
        (el: any) => el.isChecked === true
      );
      setCheckedEquipmentTypes(checkedEquipmentTypeList);
      setEquipmentTypeReviseList(EquipmentTypeListCopy);
      return null;
    });
  };
  const onEditFormSubmit = (submitData: any) => {
   
    let checkedEquipmentTypeList: any = [];
    checkedEquipmentTypes.map((item: any) => {
      checkedEquipmentTypeList.push(item.equipmentId);
      return null;
    });
    if (checkedEquipmentTypeList.length === 0) {
      setMessage("Select an Equipment Type")
      setDisplayInfoModal(true);
      return;
    }
    let HealthClassId = "";
    healthClassificationList.map((healthClassItem: any, key: any) => {
      if (
        submitData.HealthClassification === healthClassItem.healthClassification
      ) {
        HealthClassId = healthClassItem.uniqueHealthClassificationId;
      }
      return null;
    });
    let ReportClassId = "";
    reportClassificationList.map((reportClassItem: any, key: any) => {
      if (
        submitData.ReportClassification ===
        reportClassItem.flsCircutsClassificationName
      ) {
        ReportClassId = reportClassItem.flsCircutsClassificationId;
      }
      return null;
    });
    let ReviseData = 
      {
        
        NFPACode: submitData.NFPACode.trim(),
        reportClassificationId: ReportClassId,
        NFPAClassificationName: submitData.NFPAClassification.trim(),
        uniqueHealthClassificationId: HealthClassId,
        EquipmentTypeIds: checkedEquipmentTypeList
      };
    
    let UnmappedEquipmentTypeIds: any = [];
    
        UnmappedEquipmentTypeIds = nfpaClassificationDetails?.equipmentClassDetails?.filter(item => !checkedEquipmentTypeList.includes(item.uniqueEquipmentClassId)).map(item => item.uniqueEquipmentClassId)
        if(UnmappedEquipmentTypeIds?.length > 0 && nfpaClassificationDetails?.healthClassification?.uniqueHealthcareClassificationId === HealthClassId)
          {
             setSubmitData(ReviseData);
             let body = {
               UnmappedEquipmentTypeIds : UnmappedEquipmentTypeIds,
               UniqueHealthClassificationId : HealthClassId,
               IsHealthChanged : false
              };
              dispatch(checkNFPAInUseForEdit(selectedRows[0]?.uniquenfpaClassificationId,body))
          }
        else if (nfpaClassificationDetails?.healthClassification?.uniqueHealthcareClassificationId !== HealthClassId)
          {
             setSubmitData(ReviseData);
             UnmappedEquipmentTypeIds = nfpaClassificationDetails?.equipmentClassDetails?.map(item => item.uniqueEquipmentClassId)
             let body = {
               UnmappedEquipmentTypeIds : UnmappedEquipmentTypeIds,
               UniqueHealthClassificationId : nfpaClassificationDetails?.healthClassification?.uniqueHealthcareClassificationId,
               IsHealthChanged : true
              };
              dispatch(checkNFPAInUseForEdit(selectedRows[0]?.uniquenfpaClassificationId,body))
          }
        else{
              onEditNFPAClassification && onEditNFPAClassification(ReviseData);}
  };

  const onError = (errors: any, e: any) => {};

  const onHealthClassificationChange=(value)=>{
    setCheckedEquipmentTypes([]);
    let uniqueHealthClassificatioId;
       if(value === "--Select--"){
          uniqueHealthClassificatioId = "";
          setselectedHealthClassification(null)}
        else{
          healthClassificationList.map((healthClassItem: any, key: any) => {
          if (value === healthClassItem.healthClassification) {
              uniqueHealthClassificatioId = healthClassItem.uniqueHealthClassificationId;}
              return null;
      });
      setselectedHealthClassification(value)}

      dispatch(getEquipmentTypeListBsedOnHealth(uniqueHealthClassificatioId));     
  }

  
  const onConfirmClick = () => {
    onEditNFPAClassification && onEditNFPAClassification(submitData);
    setDisplayConfirmModal(false);
    setSubmitData("");
    dispatch(setInUseReturnForEdit(""));

   
  }
 
  const onCancelClick = () => {
    setDisplayInfoModal(false);
    setDisplayConfirmModal(false);
    setSubmitData("");
    dispatch(setInUseReturnForEdit(""));

  }

  const onHide = () => {
    setDisplayInfoModal(false);
    setDisplayConfirmModal(false);
    setSubmitData("");
    dispatch(setInUseReturnForEdit(""));
};

  return (
    <>
      <AddModal
        header="Edit NFPA Classification"
        showModal={showModal}
        isAdd={false}
        setShowModal={setShowModal}
        style={{ width: "35vw", maxHeight: "100%" }}
        OnSaveClick={formSubmitHandler}
      >
        <form onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={EditForm}>
          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Country</label>
                <Controller
                  name="Country"
                  control={control}
                  render={() => <span className="text-value">{Country}</span>}
                />
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="NFPACode"
                  control={control}
                  rules={{
                    required: "NFPA Code is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"NFPA Code is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="NFPACode"
                      maxLength={10}
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  NFPA Code
                </label>
                {getFormErrorMessage("NFPACode")}
              </span>
            </div>
          </div>

          {/* <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                <Controller
                  name="ReportClassification"
                  control={control}
                  rules={{
                    required: "Report Classification is required.",
                    validate:(value)=>{
                      return value==="--Select--"?"Select a Report Classification":undefined;
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId=""
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={reportClassificationLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label className="mandatory" htmlFor="Country">
                  Report Classification
                </label>
                {getFormErrorMessage("ReportClassification")}
              </span>
            </div>
          </div> */}

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="NFPAClassification"
                  control={control}
                  rules={{
                    required: "NFPA Classification is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"NFPA Classification is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="NFPAClassification"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  NFPA Classification
                </label>
                {getFormErrorMessage("NFPAClassification")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                <Controller
                  name="HealthClassification"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId=""
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => {field.onChange(e.value);onHealthClassificationChange(e.value)}}
                      options={healthClassificationLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label  htmlFor="Country">
                  Health Classification
                </label>
              </span>
            </div>
          </div>

          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-4">
              <div className="checkbox-list">
                <div className="p-float-label">
                  <label
                    className="mandatory checkbox-list-label"
                    htmlFor="checkbox"
                  >
                    Equipment Type
                  </label>
                </div>
                <ScrollPanel
                  style={{ width: "100%", height: "150px" }}
                  className="checkbox-list-scrollbar"
                >
                  {equipmentTypeReviseList && equipmentTypeReviseList.length
                    ? equipmentTypeReviseList.map((item: any, key: any) => (
                        <div className="field-checkbox px-2 pt-2" key={key}>
                          <Checkbox
                            inputId="binary"
                            id={item.equipmentId}
                            name={item.equipmentName}
                            value={item.equipmentName}
                            onChange={onEquipmentTypeChange}
                            checked={item.isChecked}
                          />
                          <label htmlFor="report1">{item.equipmentName}</label>
                        </div>
                      ))
                    : null}
                </ScrollPanel>
              </div>
            </div>
          </div>
        </form>
      </AddModal>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
 <ConfirmationBox showConfirmModal={displayConfirmModal} setShowConfirmModal={setDisplayConfirmModal} confirmAction={onConfirmClick} cancelAction ={onCancelClick} onHideAction={onHide} message={message} />
 
    </>
  );
};

export default EditNFPAClassification;
