import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { getSalesOfficeDetails } from "redux/pages/Manage/OrganizationSettings/salesOfficeSlice";

import AddModal from "components/Table/AddModal/AddModal";
import { getZoneList } from "redux/pages/Manage/OrganizationSettings/zoneSlice";

interface ISalesEdit {
  showModal: boolean;
  selectedRows: any;
  setShowModal: (state: boolean) => void;
  zone: string;
  code: string;
  onEditSalesOffice: (data: any) => void;
}

type FormData = {
  name: string;
  zone: string;
  code: string;
};

export const EditSalesOffice: React.FC<ISalesEdit> = ({
  showModal = false,
  selectedRows = [],
  setShowModal = () => {},
  zone = "",
  code = "",
  onEditSalesOffice = () => {},
}) => {
  const salesOfficeDetails = useAppSelector(
    (state) => state.salesOffice.salesOfficeDetails
  );
  const zoneList = useAppSelector((state) => state.zone.zoneList);

  const [zoneLookup, setZoneLookup] = useState([{ name: "", code: "" }]);
  const EditsaleForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      zone: undefined,
      code: "",
    },
  });
  
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getZoneList("", ""))
    dispatch(getSalesOfficeDetails(selectedRows[0]?.uniqueSalesOfficeId));
  }, [dispatch, selectedRows]);

  useEffect(() => {
    if (salesOfficeDetails) {
      setValue("zone", salesOfficeDetails?.zone?.businessZoneName);
      setValue("code", salesOfficeDetails?.code);
      setValue("name", salesOfficeDetails?.name);
    }
  }, [salesOfficeDetails, zone, code, setValue]);

  useEffect(() => {
    if (zoneList && zoneList?.length) {
      let zone: { name: string; code: string }[] = [];
      zoneList?.map((item: any) => {
        return zone.push({ name: item.name, code: item.code });
      });
      setZoneLookup(zone);
    }
  }, [zoneList, setValue]);

  const formSubmitHandler = (data: any) => {
    EditsaleForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onEditFormSubmit = (submitData: any) => {
    let zoneId = "";
    zoneList.map((zoneItem: any, key: any) => {
      if (submitData.zone === zoneItem.name) {
        zoneId = zoneItem.uniqueBusinessZoneId;
      }
      return null;
    });
    let ReviseData = 
      {
        name: submitData.name.trim(),
        code: submitData.code.trim(),
        uniqueBusinessZoneId: zoneId,
      }
    onEditSalesOffice && onEditSalesOffice(ReviseData);
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="Edit Sales Office"
        showModal={showModal}
        isAdd={false}
        setShowModal={setShowModal}
        style={{ width: "35vw", maxHeight: "100%" }}
        OnSaveClick={formSubmitHandler}
      >
        <form
          onSubmit={handleSubmit(onEditFormSubmit, onError)}
          ref={EditsaleForm}
        >
          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Sales Office Name is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Sales Office Name is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="name"
                      maxLength={100}
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Name
                </label>
                {getFormErrorMessage("name")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="code"
                  control={control}
                  rules={{
                    required: "Code is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Code is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="code"
                      maxLength={10}
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Code
                </label>
                {getFormErrorMessage("code")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                <Controller
                  name="zone"
                  control={control}
                  rules={{
                    required: "Zone is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId=""
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={zoneLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label className="mandatory" htmlFor="Country">
                  Zone
                </label>
                {getFormErrorMessage("leadTech")}
              </span>
            </div>
          </div>
        </form>
      </AddModal>
    </>
  );
};

export default EditSalesOffice;
