import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
//components
import AddInspectionField from "../Manage/AddInspectionField/AddInspectionField";
import InspectionForms from "../../../../components/common/Forms/InspectionForm/InspectionForms";
import EditInspectionField from "../Manage/EditInspectionField/EditInspectionField";
import TreeView from 'components/common/TreeView/TreeView';
import AddInspectionFieldValues from '../Manage/AddInspectionFieldValues/AddInspectionFieldValues';
import AddInspectionGroupSection from "../Manage/AddInspectionGroupSection/AddInspectionGroupSection";
import EditInspectionGroupSection from "../Manage/EditInspectionGroupSection/EditInspectionGroupSection";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//redux
import { useDispatch } from 'react-redux';
import { onAddNewInspectionFieldValue, onAddQuestionInspectionField, checkUsageOfInspectionFieldValue, onGetInspectionQuestions, onUpdateInspectionFieldValue, onUpdateQuestionInspectionField, setDeletedFieldValue, setNewFieldValue, setNewQuestionInspectionField, setupdatedFieldValue, setUpdatedQuestionInspectionField, checkUsageOfQuestionInspectionField, setDeletedQuestionInspectionField, onInspectionGroupSectionAdded, setNewGroupSection, onInspectionGroupSectionUpdated, setUpdatedGroupSection, checkUsageOfGroupSection, setDeletedGroupSection, onSetAsDefualtValue, setAsDefaultData, onGetInspectionFieldValues, setInspectionFieldValues, setInspectionQuestions, setUpdatedQuestionInspectionDatafieldOrder, onRemoveQuestionInspectionField, setIsQuestionNodeInUse, onRemoveGroupSection, setIsQuestionDataInUse} from 'redux/pages/Manage/InspectionSettings/InspectionFields/InspectionFieldSlice';
import { useAppSelector } from 'redux/hooks';
//utility
import { addDefaultDataValues, addFieldValuesWithTree, addGroupSectionWithTree, addNewInspectionFieldFromTree, convertInspectionFields, deleteGroupSectionFromTree, deleteInspectionQuestionWithTree, removeFieldValueWithTree, updateFieldValueWithTree, updateGroupSectionWithTree, updateInspectionQuestionWithTree } from '../Utility/InspectionFieldUtility';
//icons
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from "react-icons/bs";
import MappedReports from "./MappedReports";

interface QuestionPropType {
    fieldFocus?: boolean;
    setFieldFocus?:any;
}

const Question = forwardRef<any, QuestionPropType>((props, ref) => {
    const {fieldFocus = false, setFieldFocus } = props;
    const [showAddInspectionField, setShowAddInspectionField] = useState(false);
    const [showEditInspectionField, setShowEditInspectionField] = useState(false);
    const [treeData, setTreeData] = useState<any>([]);
    const [inspectionFormSetup, setInspectionFormSetup] = useState<any>([]);
    const [selectedInspection, setSelectedInspection] = useState<any>(null);
    const [showAddInspectionFieldValues, setShowAddInspectionFieldValues] = useState(false);
    const [fieldValueList, setFieldValueList] = useState<any>([]);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [showAddNode, setShowAddNode] = useState(false);
    const [showEditNode, setShowEditNode] = useState(false);
    const [showFieldOrder, setShowFieldOrder] = useState(false);
    const [showDeleteNode, setShowDeleteNode] = useState(false);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [expandedKeys, setExpandedKeys] = useState({});
    const inspectionRef = useRef<any>(null);
    const [showFieldPrompt, setShowFieldPrompt] =  useState(false);
    const [showNodePrompt, setShowNodePrompt] =  useState(false);
    const [deleteNode, setDeleteNode] =  useState(false);

    const dispatch = useDispatch<any>();
    const inspectionQuestions = useAppSelector((state) => state.InspectionField.inspectionQuestions);
    const newInspectionField = useAppSelector((state) => state.InspectionField.newQuestionInspectionField);
    const newFieldValue = useAppSelector((state) => state.InspectionField.newFieldValue);
    const updatedFieldValue = useAppSelector((state) => state.InspectionField.updatedFieldValue);
    const deletedFieldValue = useAppSelector((state) => state.InspectionField.deletedFieldValue);
    const updatedQuestionInspectionField = useAppSelector((state) => state.InspectionField.updatedQuestionInspectionField);
    const deletedQuestionInspectionField = useAppSelector((state) => state.InspectionField.deletedQuestionInspectionField);
    const newGroupSection = useAppSelector((state) => state.InspectionField.newGroupSection);
    const updatedGroupSection = useAppSelector((state) => state.InspectionField.updatedGroupSection);
    const deletedGroupSection = useAppSelector((state) => state.InspectionField.deletedGroupSection);
    const isQuestionDataInUse = useAppSelector((state) => state.InspectionField.isQuestionDataInUse);
    const defaultData = useAppSelector((state) => state.InspectionField.DefaultData);
    const inspectionFieldValues = useAppSelector((state) => state.InspectionField.inspectionFieldValues);
    const updatedQuestionsInspectionDatafieldOrder = useAppSelector((state) => state.InspectionField.updatedQuestionInspectionDatafieldOrder);
    const isQuestionNodeInUse = useAppSelector((state) => state.InspectionField.isQuestionNodeInUse);
    const [mappedReports, setMappedReports] = useState([]);

    useImperativeHandle(ref, () => ({
        requestSubmit: () => {
            inspectionRef?.current?.requestSubmit()
        },       
    }));

    useEffect(() => {
        if (inspectionFormSetup && deleteNode==true) {
            onDeleteParentGroupSections(inspectionFormSetup)
            setDeleteNode(false);
        }
    }, [inspectionFormSetup,deleteNode])

    const onAddClick = useCallback((data) => {
        if (data?.isValidated) {
            setShowAddInspectionFieldValues(true);
            setFieldValueList(data?.validatedValues)
        }
        else
            setShowAddInspectionField(true);
        setSelectedInspection(data)
    }, [showAddInspectionField, selectedInspection, showAddInspectionFieldValues, fieldValueList]);

    const onEditClick = useCallback((data) => {
        if (data) {
            setShowEditInspectionField(true);
            setSelectedInspection(data);
        }
        else{
         setShowEditNode(true);
        }
    }, [showEditInspectionField, selectedInspection]);

    const onDeleteClick = useCallback((data) => {
        if (data) {
            setShowDeletePrompt(true);
            setSelectedInspection(data);
        }
        else{
            setDeleteNode(true);
        }
    }, [showDeletePrompt])

    useEffect(() => {
        dispatch(onGetInspectionQuestions());
        dispatch(onGetInspectionFieldValues());
    }, []);

    const filterData = (tree, value) => {
        if (tree.length) {
            var result;
            for (let i = 0; i < tree?.length; i++) {
                if (value === tree[i].key)
                    return tree[i];
                let temp = filterData(tree[i]?.children, value);
                if (temp)
                    return result = temp
            }
            return result;
        }
    }
    const renderHeader = () => {
        return(
            <>
                Tech Advance<sup>+</sup>
            </>
        )
    }
    useEffect(() => {
        if (inspectionQuestions?.length) {
           if(showFieldOrder){         
               let value: any = JSON.stringify(inspectionQuestions?.[0]?.inspectionGroupSections);
               value = value.replace(/\"inspectionGroupSectionId\":/g, '"key":');
               value = value.replace(/\"inspectionGroupSectionName\":/g, '"label":');
               value = value.replace(/\"inspectionGroupSections\":/g, '"children":');
               value = JSON.parse(value);
               setTreeData(value);
               const data = filterData(value, selectedItem);
               let convList = convertInspectionFields({ ...data })
               setInspectionFormSetup(convList);
               dispatch(setInspectionQuestions(""));
           }
           else{
            let value: any = JSON.stringify(inspectionQuestions?.[0]?.inspectionGroupSections);
            value = value.replace(/\"inspectionGroupSectionId\":/g, '"key":');
            value = value.replace(/\"inspectionGroupSectionName\":/g, '"label":');
            value = value.replace(/\"inspectionGroupSections\":/g, '"children":');
            value = JSON.parse(value);
            setTreeData(value);
            let convList = convertInspectionFields(value[0])
            setInspectionFormSetup(convList);
            setSelectedItem(value[0]?.key)
            dispatch(setInspectionQuestions(""));
           }
        }
    }, [inspectionQuestions]);

    useEffect(() => {
        if (inspectionFormSetup) {
            dispatch(onGetInspectionFieldValues());
        }
    }, [inspectionFormSetup])

    useEffect(() => {
        if (updatedQuestionsInspectionDatafieldOrder) {
            setDisplayInfoModal(true);
            setMessage("Field Order updated");
            dispatch(setUpdatedQuestionInspectionDatafieldOrder(""));
            dispatch(onGetInspectionQuestions());
            setShowFieldOrder(true);
        }
    }, [updatedQuestionsInspectionDatafieldOrder]);

    useEffect(() => {
        if (newInspectionField) {
            if (newInspectionField?.isSuccess) {
                let convNewField = JSON.parse(JSON.stringify(newInspectionField?.data))
                setShowAddInspectionField(false);
                const [form, node] = addNewInspectionFieldFromTree(selectedInspection, inspectionFormSetup, treeData, convNewField);
                setInspectionFormSetup({ ...node });
                setTreeData([...form]);
                if (convNewField?.isValidated) {
                    setShowAddInspectionFieldValues(true);
                } else {
                    setMessage("Data Field Added");
                    setDisplayInfoModal(true);
                    dispatch(setNewQuestionInspectionField(""));
                }
            } else {
                setMessage(newInspectionField?.message);
                setDisplayInfoModal(true);
            }

        }
    }, [newInspectionField]);

    useEffect(() => {
        if (newFieldValue) {
            if (newFieldValue?.isSuccess) {
                let newField = JSON.parse(JSON.stringify(newFieldValue?.validatedFields));
                const selectedFields = newInspectionField?.data ? newInspectionField?.data : selectedInspection;
                const [form, node] = addFieldValuesWithTree(selectedFields, inspectionFormSetup, treeData, newField, fieldValueList);
                setInspectionFormSetup({ ...node });
                setTreeData([...form]);
                setFieldValueList([...fieldValueList]);
                setMessage("Additional Data Field Value added");
                setDisplayInfoModal(true);
            } else {
                setMessage(newFieldValue?.message);
                setDisplayInfoModal(true);
            }
            dispatch(setNewFieldValue(""));
        }
    }, [newFieldValue]);

    useEffect(() => {
        if (updatedFieldValue) {
            if (updatedFieldValue?.isSuccess) {
                const selectedFields = newInspectionField?.data ? newInspectionField?.data : selectedInspection;
                const [form, node] = updateFieldValueWithTree(selectedFields, inspectionFormSetup, treeData, updatedFieldValue?.validatedFields, fieldValueList);
                setInspectionFormSetup({ ...node });
                setTreeData([...form]);
                setFieldValueList([...fieldValueList]);
                setMessage("Additional Data Field Value updated");
                setDisplayInfoModal(true);
            } else {
                setMessage(updatedFieldValue?.message);
                setDisplayInfoModal(true);
            }
            dispatch(setupdatedFieldValue(""));
        }
    }, [updatedFieldValue]);

    useEffect(() => {
        if (deletedFieldValue) {
            const selectedFields = newInspectionField?.data ? newInspectionField?.data : selectedInspection;
            const [form, node] = removeFieldValueWithTree(selectedFields, inspectionFormSetup, treeData, deletedFieldValue, fieldValueList);
            setInspectionFormSetup({ ...node });
            setTreeData([...form]);
            dispatch(setDeletedFieldValue(""));
            setFieldValueList([...fieldValueList]);
            setMessage("Selected Additional Data Field Value deleted");
            setDisplayInfoModal(true);
            setShowDeletePrompt(false);
        }
    }, [deletedFieldValue]);

    useEffect(() => {
        if (updatedQuestionInspectionField) {
            if (updatedQuestionInspectionField?.isSuccess) {
                let convField = JSON.parse(JSON.stringify(updatedQuestionInspectionField?.data));
                if (convField?.validatedValues.length) {
                    convField?.validatedValues.forEach(element => {
                        element.dataFieldLookupId = element.dataFieldLookupId.toString();
                    });
                }
                const [form, node] = updateInspectionQuestionWithTree(selectedInspection, inspectionFormSetup, treeData, convField);
                setInspectionFormSetup({ ...node });
                setTreeData([...form]);
                setShowEditInspectionField(false);
                setMessage("Data Field Updated");
                setDisplayInfoModal(true);
            } else {
                setMessage(updatedQuestionInspectionField?.message);
                setDisplayInfoModal(true);
            }
            dispatch(setUpdatedQuestionInspectionField(""));
        }
    }, [updatedQuestionInspectionField]);

    useEffect(() => {
        if (deletedQuestionInspectionField) {
            const [form, node] = deleteInspectionQuestionWithTree(selectedInspection, inspectionFormSetup, treeData, deletedQuestionInspectionField);
            setInspectionFormSetup({ ...node });
            setTreeData([...form]);
            setShowDeletePrompt(false);
            setShowFieldPrompt(false);
            dispatch(setDeletedQuestionInspectionField(""));
            setMessage("Data Field Deleted");
            setDisplayInfoModal(true);
        }
    }, [deletedQuestionInspectionField])

    useEffect(() => {
        if (newGroupSection) {
            if (newGroupSection?.isSuccess) {
                const [form, node] = addGroupSectionWithTree(inspectionFormSetup, treeData, newGroupSection?.data);
                setInspectionFormSetup({ ...node });
                setTreeData([...form]);
                setShowAddNode(false);
                setMessage("Group Section Added");
                setDisplayInfoModal(true);
            } else {
                setMessage(newGroupSection?.message);
                setDisplayInfoModal(true);
            }
            dispatch(setNewGroupSection(""));
        }
    }, [newGroupSection]);

    useEffect(() => {
        if (updatedGroupSection) {
            if (updatedGroupSection?.isSuccess) {
                const [form, node] = updateGroupSectionWithTree(inspectionFormSetup, treeData, updatedGroupSection?.data);
                setInspectionFormSetup({ ...node });
                setTreeData([...form]);
                setShowEditNode(false);
                setMessage("Group Section Updated");
                setDisplayInfoModal(true);
            } else {
                setMessage(newGroupSection?.message);
                setDisplayInfoModal(true);
            }
            dispatch(setUpdatedGroupSection(""));
        }
    }, [updatedGroupSection])

    useEffect(() => {
        if (deletedGroupSection) {
            const [form, node] = deleteGroupSectionFromTree(inspectionFormSetup, treeData, deletedGroupSection);
            setInspectionFormSetup({ ...node });
            setTreeData([...form]);
            setSelectedItem(node?.key);
             setShowNodePrompt(false);
            setShowDeleteNode(false);
            dispatch(setDeletedGroupSection(""));
            setMessage("Group Section Deleted");
            setDisplayInfoModal(true);
        }
    }, [deletedGroupSection])

    useEffect(() => {
        if (isQuestionDataInUse && isQuestionDataInUse.length > 0) {
            setShowFieldPrompt(true);
            setShowDeleteNode(false);
            setMessage(isQuestionDataInUse[0]?.customMessage);
            setMappedReports(isQuestionDataInUse[0]?.mappingReportList);
            dispatch(setDeletedGroupSection(""));
            dispatch(setDeletedQuestionInspectionField(""));
            setShowDeletePrompt(false);
            dispatch(setIsQuestionDataInUse([]));
        }
    }, [isQuestionDataInUse])   
    
    useEffect(() => {
        if (isQuestionNodeInUse && isQuestionNodeInUse.length > 0) {
            setShowNodePrompt(true);
            setMessage(isQuestionNodeInUse[0]?.customMessage);
            setMappedReports(isQuestionNodeInUse[0]?.mappingReportList);
            setShowDeleteNode(false);
            dispatch(setDeletedGroupSection(""));
            dispatch(setDeletedQuestionInspectionField(""));
            setShowDeletePrompt(false);
            dispatch(setIsQuestionNodeInUse([]));
        }
    }, [isQuestionNodeInUse])   

    useEffect(() => {
        if (defaultData) {
            let conv = JSON.parse(JSON.stringify(inspectionFieldValues))
            const result = addDefaultDataValues(conv, defaultData?.fieldDetails);
            dispatch(setInspectionFieldValues(result));
            setMessage("Default Data Item Updated");
            setDisplayInfoModal(true);
            dispatch(dispatch(setAsDefaultData("")));
        }
    }, [defaultData])

    useEffect(() => {
        if (treeData?.length) {
            let _expandedKeys = {};
            for (let node of treeData) {
                expandNode(node, _expandedKeys);
            }
            setExpandedKeys(_expandedKeys);
        }
    }, [treeData])

    const expandNode = (node, _expandedKeys) => {
        if (node.children && node.children.length) {
            _expandedKeys[node.key] = true;

            for (let child of node.children) {
                expandNode(child, _expandedKeys);
            }
        }
    }

    const onNodeClick = useCallback((data) => {
        let convList = convertInspectionFields(data)
        setInspectionFormSetup(convList);
        setSelectedItem(data?.key);
    }, [selectedItem]);

    const onSubmitInspection = useCallback((body) => {
        dispatch(onAddQuestionInspectionField(body));
    }, []);

    const onAddFieldValue = useCallback((fieldName, reset) => {
        const selectedFields = newInspectionField?.data ? newInspectionField?.data : selectedInspection;
        let body = {
            fieldvalue: fieldName,
            dataEntryControlId: selectedFields?.dataEntryControl?.dataEntryControlId
        };
        dispatch(onAddNewInspectionFieldValue(selectedFields?.uniqueDataFieldId, body, reset));
    }, [selectedInspection, newInspectionField]);

    const onUpdateFieldValue = useCallback((newData) => {
        const selectedFields = newInspectionField?.data ? newInspectionField?.data : selectedInspection;
        let body = {
            fieldvalue: newData?.dataFieldLookupValue
        }
        dispatch(onUpdateInspectionFieldValue(selectedFields?.uniqueDataFieldId, newData.dataFieldLookupId, body, newData));
    }, [selectedInspection, newInspectionField]);

    const onDeleteFieldValue = useCallback((deletedItem) => {
        const selectedFields = newInspectionField?.data ? newInspectionField?.data : selectedInspection;
        dispatch(checkUsageOfInspectionFieldValue(selectedFields?.uniqueDataFieldId, deletedItem?.dataFieldLookupId, deletedItem));
    }, [selectedInspection, newInspectionField]);

    const onUpdateInspection = useCallback((body) => {
        dispatch(onUpdateQuestionInspectionField(body?.uniqueDataFieldId, body));
    }, []);

    const onDeleteInspectionField = useCallback((deletedItem) => {
        let body = {
            isSection: deletedItem?.isSection
        }
        dispatch(checkUsageOfQuestionInspectionField(deletedItem?.uniqueDataFieldId, body, deletedItem))
    }, [])

    const onNodeSaved = useCallback((body) => {
        dispatch(onInspectionGroupSectionAdded(body))
    }, []);

    const onNodeUpdated = useCallback((body) => {
        dispatch(onInspectionGroupSectionUpdated(inspectionFormSetup?.key, body))
    }, [inspectionFormSetup]);

    const onDeleteGroupSection = (data) => {
        dispatch(checkUsageOfGroupSection(data?.key))
    }

    const onDeleteParentGroupSections = (data) => {
        if(data.level===2 || inspectionFormSetup.level===2){
            setMessage("Parent Node cannot be deleted");
            setDisplayInfoModal(true);
        }
        else{
            setShowDeleteNode(true)
        }
    }

    const onInspectionSubmit = useCallback((data) => {
        const body = {
            fieldDetails: data,
            nodeId: inspectionFormSetup?.key
        }
        dispatch(onSetAsDefualtValue(body));
    }, [inspectionFormSetup])

    const renderFooter = () => {
        return (
            <>
                <Button label="No" onClick={() => setShowDeletePrompt(false)} className='cancel-btn' />
                <Button label="Yes" onClick={() => onDeleteInspectionField(selectedInspection)} className='submit-btn' autoFocus />
            </>
        );
    }

    const renderDeleteFooter = () => {
        return (
            <>
                <Button label="No" onClick={() => setShowDeleteNode(false)} className='cancel-btn' />
                <Button label="Yes" onClick={() => onDeleteGroupSection(inspectionFormSetup)} className='submit-btn' autoFocus />
            </>
        );
    }

    const checkLevelOfNode = () => {
        if (inspectionFormSetup) {
            if (inspectionFormSetup.level && inspectionFormSetup.level <= 3) {
                setShowAddNode(true);
            } else {
                setShowAddNode(false);
                setMessage("Maximum level reached");
                setDisplayInfoModal(true);
            }
        }
    }

    const renderInspectionFieldDeleteFooter = () => {
        return (
            <>
                <Button label="No" onClick={() => setShowFieldPrompt(false)} className='cancel-btn-no' />
                <Button label="Yes" onClick={() => onConfirmDeleteInspectionField(selectedInspection)} className='save-btn-mapped-reports'/>
            </>
        );
    }

    const onConfirmDeleteInspectionField = useCallback((selectedInspection) => {
        let body = {
            isSection: selectedInspection?.isSection
        }
        dispatch(onRemoveQuestionInspectionField(selectedInspection?.uniqueDataFieldId, body, selectedInspection));
    }, [])

    const renderNodeDeleteFooter = () => {
        return (
            <>
                <Button label="No" onClick={() => { setShowNodePrompt(false) }} className='cancel-btn-no' />
                <Button label="Yes" onClick={() => onConfirmNodeDelete(inspectionFormSetup)} className='save-btn-mapped-reports'/>
            </>
        );
    }

    const onConfirmNodeDelete = useCallback((inspectionFormSetup) => {
        dispatch(onRemoveGroupSection(inspectionFormSetup?.key));
    }, [])

    return (
        <div className="question">
            <div className="tree-wrapper">
                <div className="header">
                    <div className="action-btns">
                        <Button
                            tooltip="Add"
                            tooltipOptions={{ position: "top" }}
                            className="button-icon"
                            onClick={checkLevelOfNode}
                        >
                            <BsPlusLg className="icon" />
                        </Button>
                        <Button
                            tooltip="Edit"
                            tooltipOptions={{ position: "top" }}
                            className="button-icon"
                            onClick={() => setShowEditNode(true)}
                        >
                            <BsFillPencilFill className="icon" />
                        </Button>
                        <Button
                            tooltip="Delete"
                            tooltipOptions={{ position: "top" }}
                            className="button-icon"
                            onClick={() =>  onDeleteParentGroupSections(inspectionFormSetup)}
                        >
                            <BsTrashFill className="icon" />
                        </Button>
                    </div>
                </div>
                <ScrollPanel className="treeview-scroll">
                    <TreeView
                        selectionMode="single"
                        onNodeClick={onNodeClick}
                        treeData={treeData}
                        onToggle={e => setExpandedKeys(e.value)}
                        expandedKeys={expandedKeys}
                        selectedItem={selectedItem}
                        isCustomTemplateForNode = {true }/>
                </ScrollPanel>
            </div>
            <InspectionForms
                type='QUESTION'
                ref={inspectionRef}
                isExpanded={false}
                isAddAction={true}
                isCheckedAction={true}
                formElements={inspectionFormSetup}
                inspectionFieldValues={inspectionFieldValues}
                onAddClicked={onAddClick}
                onEditClicked={onEditClick}
                onDeleteClicked={onDeleteClick}
                onInspectionSubmit={onInspectionSubmit} 
                isInspectionField = {true} 
                fieldFocus = {fieldFocus} 
                selectedItem={selectedItem}
                setFieldFocus = {setFieldFocus}/>
            {showAddInspectionField &&
                <AddInspectionField
                    type='QUESTION'
                    selectedNode={inspectionFormSetup}
                    parentItem={selectedInspection}
                    showModal={showAddInspectionField}
                    onSubmitInspection={onSubmitInspection}
                    onHideModal={() => setShowAddInspectionField(false)}
                />
            }
            {showEditInspectionField &&
                <EditInspectionField
                    type='QUESTION'
                    selectedItem={selectedInspection}
                    selectNode={inspectionFormSetup}
                    showModal={showEditInspectionField}
                    onUpdate={onUpdateInspection}
                    onHideModal={() => setShowEditInspectionField(false)} />
            }
            {showAddInspectionFieldValues &&
                <AddInspectionFieldValues
                    selectedField={newInspectionField?.data ? newInspectionField?.data : selectedInspection}
                    fieldValueList={fieldValueList}
                    onAddFieldValue={onAddFieldValue}
                    onUpdateFieldValue={onUpdateFieldValue}
                    onDeleteFieldValue={onDeleteFieldValue}
                    onHideModal={() => {
                        setShowAddInspectionFieldValues(false);
                        dispatch(setNewQuestionInspectionField(""));
                        setFieldValueList([]);
                    }}
                    showModal={showAddInspectionFieldValues} />
            }
            {showDeletePrompt &&
                <Dialog
                    header={renderHeader()}
                    visible={showDeletePrompt}
                    style={{ width: '35vw' }}
                    footer={renderFooter()}
                    onHide={() => setShowDeletePrompt(false)}
                    className='delete-modal'
                >
                    {selectedInspection?.isSection ?
                        <p className='content'>Are you sure you want to delete the selected Data Group ?</p> :
                        <p className='content'>Are you sure you want to delete the selected Data Item ?</p>
                    }
                </Dialog>
            }
            {showAddNode &&
                <AddInspectionGroupSection
                    showModal={showAddNode}
                    selectedNode={inspectionFormSetup}
                    onNodeSaved={onNodeSaved}
                    onHideModal={() => setShowAddNode(false)}
                />
            }
            {showEditNode &&
                <EditInspectionGroupSection
                    showModal={showEditNode}
                    selectedNode={inspectionFormSetup}
                    onNodeUpdated={onNodeUpdated}
                    onHideModal={() => setShowEditNode(false)}
                />
            }
            {showDeleteNode &&
                <Dialog
                    header={renderHeader()}
                    visible={showDeleteNode}
                    style={{ width: '35vw' }}
                    footer={renderDeleteFooter()}
                    onHide={() => setShowDeleteNode(false)}
                    className='delete-modal'
                >
                    <p className='content'>Are you sure you want to delete the Group Section ?</p>
                </Dialog>
            }
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            {showFieldPrompt &&
                <MappedReports
                    showModal={showFieldPrompt}
                    setShowModal={setShowFieldPrompt}
                    MappedReportNames={mappedReports}
                    message={message}
                    footer={renderInspectionFieldDeleteFooter()}
                >
                </MappedReports>
            }
            {showNodePrompt &&
                <MappedReports
                    showModal={showNodePrompt}
                    setShowModal={setShowNodePrompt}
                    MappedReportNames={mappedReports}
                    message={message}
                    footer={renderNodeDeleteFooter()}
                >
                </MappedReports>
            }
        </div>
    );
});

export default memo(Question);
