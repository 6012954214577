import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';




const reportclassification = [
    { label: 'Alarm Initiating Devices', value: 'Alarm Initiating Devices' },
    { label: 'Control Devices / Relays', value: 'Control Devices / Relays' },
    { label: 'General System Modules', value: 'General System Modules' },
    { label: 'Unidentified', value: 'Unidentified' }
];


const reportEditor = (options) => {
    return (
        <Dropdown value={options.value} options={reportclassification} optionLabel="label" optionValue="value" placeholder="Select"
            itemTemplate={(option) => {
                return <span className='status-badge'>{option.label}</span>
            }} />
    );
}

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

export const NFPA_CLASSIFICATION_COLS = {
    NFPA_COLS: [
        
        {
            field: 'nfpaCode',
            header: 'NFPA Code',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'nfpaClassification',
            header: 'NFPA Classification',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'equipmentType',
            header: 'Equipment Type',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'healthClassification',
            header: 'Health Classification',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        // {
        //     field: 'reportClassification',
        //     header: 'Report Classification',
        //     editor: (options) => reportEditor(options),
        //     style: { flex: '1 0 150px' },
        //     sortable: true,
        // }
       
    ]
};
