import React, { memo, useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { DataTableComponent } from "components/Table/DataTable";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { AiOutlineExpandAlt, AiOutlineShrink } from "react-icons/ai";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";

import { ResponsiveContainer, BarChart, XAxis, Legend, Bar, Tooltip, YAxis, LabelList } from "recharts";

// Utility
import { getTaskListGridColumns } from "./utility";
import { showUI } from './../../../../service/GeneralUtility';
import { customBarChartTooltip, customContractChartTooltip } from "../../utility";
import { INSPECTION_TASKS_COL, EQUIPMENT_COLS, CONTRACT_COLS } from "../../DashboardData";

// Routing
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/AppRoutes";

// Redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import {
	getReportsGenerated,
	getEquipmentSummary,
	getFailedEquipments,
	closeInspectionTasks,
	setSelectedFailedEquipment,
	setEquipmentSummary,
	setReportSummary
} from "redux/pages/Dashboard/dashboardSlice";
import {
	getActiveInspectionTasks,
	getClosedInspectionTasks,
	getCompletedInspectionTasks,
	getExpiredContractList,
	setInspectionSearchId
} from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
import { setAsActiveSiteForSite, setActiveSiteForSite } from "redux/pages/Administration/Site/siteSlice";
import { setDefaultValues } from "redux/pages/Inspection/InspectionTask/inspectionTaskFilterSlice";
import { setContractSearchId } from "redux/pages/Inspection/Contracts/contractSlice";
import PopOver from "components/common/PopOver/PopOver";
import { FaChevronDown, FaDownload } from "react-icons/fa";
import { OverlayPanel } from "primereact/overlaypanel";
import { onExportReport } from "redux/common/report/customizeReportSlice";
import FaileEquipmentReport from "./Reports/FailedEquipmentReport";

interface PropType {
	defaultSite?: any;
}

const InspectionSummary: React.FC<PropType> = (props) => {

	const { defaultSite } = props;

	const [key, setKey] = useState("activeInspection");
	const [minToggle, setMinToggle] = useState(true);
	const [gridData, setGridData] = useState<any>([]);
	const [columns, setColumns] = useState<any>([]);
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
	const [filterOptions, setFilterOptions] = useState<any>(['availableTasksToQuit', 'unavailableTasksToQuit']);
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const [selectedInspectionTasks, setSelectedInspectionTasks] = useState<any>({});
	const [closeTaskInProgress, setCloseTaskInProgress] = useState<boolean>(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [pageDetails, setPageDetails] = useState<any>({});
	const [sortField, setSortField] = useState("");
	const [sortOrder, setSortOrder] = useState("");
	const [currentStart, setCurrentStart] = useState(1);
	const [activeSiteId, setActiveSiteId] = useState<any>("");
	const [isNavigate, setIsNavigate] = useState<boolean>(false);
	const [targetPath, setTargetPath] = useState("");
	const downloadRef = React.createRef<OverlayPanel>();
	const [showReportModal, setShowReportModal] = useState(false);
    const [reportFlag, setReportFlag] = useState<any>('all');
	const [tableColumns, setTableColumns] = useState<Array<any>>([]);

	const navigate = useNavigate();
	const dispatch = useDispatch<any>();
	const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
	const equipmentSummary = useAppSelector((state) => state.dashboard.equipmentSummary);
	const reportSummaryData = useAppSelector((state) => state.dashboard.reportSummary);
	const activeInspectionTasks = useAppSelector((state) => state.inspectionTask.activeInspectionTasks);
	const closedInspectionTasks = useAppSelector((state) => state.inspectionTask.closedInspectionTasks);
	const completedInspectionTasks = useAppSelector((state) => state.inspectionTask.completedInspectionTasks);
	const failedEquipments = useAppSelector((state) => state.dashboard.failedEquipments);
	const expiredContractList = useAppSelector((state) => state.inspectionTask.expiredContractList);
	const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

	const taskStatuses = {
        1: 'Active',
        3: 'Closed',
        11: 'Completed',
        36: 'Inactive'
    };

	useEffect(() => {	
		if (key && (defaultSite && defaultSite.uniqueSiteId)) {		
			if (key === "activeInspection") {
				if (filterOptions.length === 1) {
					if (filterOptions[0] === 'availableTasksToQuit') {
						dispatch(getActiveInspectionTasks(currentStart, '', '', false, true));
					} else {
						dispatch(getActiveInspectionTasks(currentStart, '', '', false, false));
					}
				} else {
					dispatch(getActiveInspectionTasks(1, '', '', true, false));
				}
			} else if (key === "closedInspection") {			
				dispatch(getClosedInspectionTasks(1, '', ''));
			} else if (key === "completedInspection") {
				dispatch(getCompletedInspectionTasks(1, '', ''));
			} else if (key === "failedEquipment") {
				dispatch(getFailedEquipments(defaultSite.uniqueSiteId, 1, '', ''));
			} else if (key === "expiredContracts") {
				dispatch(getExpiredContractList(1, '', ''));
			}
		}
	}, [dispatch, defaultSite, key, filterOptions, rowsPerPage]);

	useEffect(() => {		
		if (isNavigate && targetPath && (defaultSite.uniqueSiteId === activeSiteId)) {		
			setIsNavigate(false);
			navigate(`/${targetPath}`);
		}
	}, [isNavigate, targetPath, activeSiteId, defaultSite, navigate])

	useEffect(() => {		
		if ((activeInspectionTasks && activeInspectionTasks.data) && (key === "activeInspection")) {
			const column = getTaskListGridColumns(activeInspectionTasks?.generalProperties);
            setColumns([...column]);
			setGridData([...activeInspectionTasks.data]);			
			if (selectAll) {			
				setSelectedRows([...activeInspectionTasks.data]);
			}
			setPageDetails(activeInspectionTasks.pageDetails);
		}
	}, [activeInspectionTasks]);

	useEffect(() => {
		if ((closedInspectionTasks && closedInspectionTasks.inspectionTaskDetails) && (key === "closedInspection")) {
			setGridData([...closedInspectionTasks.inspectionTaskDetails]);
			setPageDetails(closedInspectionTasks.pageDetails);
		}
	}, [closedInspectionTasks]);

	useEffect(() => {
		if ((completedInspectionTasks && completedInspectionTasks.inspectionTaskDetails) && (key === "completedInspection")) {
			setGridData([...completedInspectionTasks.inspectionTaskDetails]);
			setPageDetails(completedInspectionTasks.pageDetails);
		}
	}, [completedInspectionTasks]);

	useEffect(() => {
		if ((failedEquipments && failedEquipments.failedEquipments) && (key === "failedEquipment")) {
			setGridData([...failedEquipments.failedEquipments]);
			setPageDetails(failedEquipments.pageDetails);
		}
	}, [failedEquipments]);

	useEffect(() => {
		if ((expiredContractList && expiredContractList.contractDetails) && (key === "expiredContracts")) {
			setGridData([...expiredContractList.contractDetails]);
			setPageDetails(expiredContractList.pageDetails);
		}
	}, [expiredContractList]);

	useEffect(() => {
		if (key && rowsPerPage && rowsPerPage > 0) {
			let modifiedPageDetails: any = {};
			switch (key) {
				case "activeInspection":
					modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setPageDetails(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0);
					break;
				case "closedInspection":
					modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setPageDetails(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0);
					break;
				case "completedInspection":
					modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setPageDetails(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0);
					break;
				case "failedEquipment":
					modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setPageDetails(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0);
					break;
				case "expiredContracts":
					modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setPageDetails(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0);
					break;
			}
		}
	}, [rowsPerPage]);

	const handleRowSelect = useCallback((data: any) => {
		if (data && data.length) {
			setSelectedRows([...data]);			
		} else if (selectAll) {
			setSelectedRows([...gridData]);
		} else {
			setSelectedRows(gridData.length ? [gridData[0]] : []);
		}
	}, [gridData, selectAll]);

	const toggleHandler = () => {	
		if (minToggle) {
			dispatch(setEquipmentSummary([]));
			dispatch(setReportSummary([]));
			if (userPrivileges.length) {
				dispatch(getReportsGenerated());
				dispatch(getEquipmentSummary());
			}
			setMinToggle(false)
		} else {
			dispatch(setEquipmentSummary([]));
			dispatch(setReportSummary([]));
			setMinToggle(true);
		}
	};

	const changeTab = (tab: any) => {
		if (tab !== key) {
			setGridData([]);	
			setSelectedRows([]);
			setPageDetails(null);
			setPageIndex(0);
			setCurrentStart(1);
			setSortField("");
			setSortOrder("");
			setSelectAll(false);
			setKey(tab);
		}
	};

	const onReportAllClick = () => {
		setTableColumns(EQUIPMENT_COLS);
        setReportFlag('all');
        setShowReportModal(true);
    };

    const onReportHighlightedClick = () => {
		setTableColumns(EQUIPMENT_COLS);
        setReportFlag('highlighted');
        setShowReportModal(true);
    };
	const onExportAll = () => {
		const reportParameters = {
            exportParameters: {
                sortColumn: sortField,
                sortOrder: sortOrder,
                selectedIds: ""
            },
            exportType: "Failed Equipment Report"
        };
		dispatch(onExportReport(reportParameters, "Failed Equipment"));
	};

	const onExportHighlightedClick = () => {
		const reportParameters = {
            exportParameters: {
                sortColumn: sortField,
                sortOrder: sortOrder,
                selectedIds: getSelectedids()
            },
            exportType: "Failed Equipment Report"
        };
		dispatch(onExportReport(reportParameters, "Failed Equipment"));
	};
	const getSelectedids = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.equipmentId);
        });
        return params;
    };

	const onPageChanged = (options: any) => {
		if (!closeTaskInProgress && (pageIndex !== options)) {		
			let currentStart = (options * pageDetails?.currentLength) + 1;
			setCurrentStart(currentStart);
			setPageIndex(options);
			if (key === "activeInspection") {
				if (filterOptions.length && filterOptions.length === 1) {
					if (filterOptions[0] === 'availableTasksToQuit') {
						dispatch(getActiveInspectionTasks(currentStart, sortField, sortOrder, false, true));
					} else {
						dispatch(getActiveInspectionTasks(currentStart, sortField, sortOrder, false, false));
					}
				} else {
					dispatch(getActiveInspectionTasks(currentStart, sortField, sortOrder, true, false));
				}
			} else if (key === "closedInspection") {
				dispatch(getClosedInspectionTasks(currentStart, sortField, sortOrder));
			} else if (key === "completedInspection") {
				dispatch(getCompletedInspectionTasks(currentStart, sortField, sortOrder));
			} else if (key === "failedEquipment") {
				dispatch(getFailedEquipments(defaultSite.uniqueSiteId, currentStart, sortField, sortOrder));
			} else if (key === "expiredContracts") {
				dispatch(getExpiredContractList(currentStart, sortField, sortOrder));
			}
		}
	};

	const onSort = (event) => {
		if (gridData && gridData.length) {
			let sortDirection: string;
			if (event.sortOrder === 1) {
				sortDirection = "ASC";
			} else {
				sortDirection = "DESC";
			}
			setSortField(event.sortField);
			setSortOrder(sortDirection);
			if (key === "activeInspection") {
				if (filterOptions.length === 1) {
					if (filterOptions[0] === 'availableTasksToQuit') {
						dispatch(getActiveInspectionTasks(currentStart, event.sortField, sortDirection, false, true));
					} else {
						dispatch(getActiveInspectionTasks(currentStart, event.sortField, sortDirection, false, false));
					}
				} else {
					dispatch(getActiveInspectionTasks(currentStart, event.sortField, sortDirection, true, false));
				}
			} else if (key === "closedInspection") {
				dispatch(getClosedInspectionTasks(currentStart, event.sortField, sortDirection));
			} else if (key === "completedInspection") {
				dispatch(getCompletedInspectionTasks(currentStart, event.sortField, sortDirection));
			} else if (key === "failedEquipment") {
				dispatch(getFailedEquipments(defaultSite.uniqueSiteId, currentStart, event.sortField, sortDirection));
			} else if (key === "expiredContracts") {
				dispatch(getExpiredContractList(currentStart, event.sortField, sortDirection));
			}
		}
	};

	const onChangeFilterOptions = (event: any) => {
		setSortField("");
		setSortOrder("");
		let selectItems = [...filterOptions];
		if (event.checked) {
			selectItems.push(event.value);
		} else {
			selectItems.splice(selectItems.indexOf(event.value), 1);
		}
		setFilterOptions(selectItems);
	};

	const onChangeSelectAll = (event: any) => {
		setSortField("");
		setSortOrder("");
		if (event) {
			if (event.checked) {
				setSelectAll(true);			
				setSelectedRows([...gridData]);
			} else {
				setSelectAll(false);				
				setSelectedRows([gridData.length ? gridData[0] : []]);
			}
		}
	}	

	const closeTaskHandler = () => {
		if (selectedRows && selectedRows.length) {
			if ((filterOptions.length === 1) && (filterOptions[0] == 'availableTasksToQuit') && (selectedRows.length > 1)) {
				setMessage("This operation can be performed only one row at a time");
				setDisplayInfoModal(true);
			} else {
				let body: any = {};
				if (selectAll) {
					body = {
						isChecked: true
					}
				} else {
					let tasks: any = [];
					selectedRows.forEach(element => {
						if (element.InspectionTaskId) {
							tasks.push(element.InspectionTaskId)
						}
					});
					body = {
						inspectionTaskIds: tasks,
						isrequiredquitting: true
					};
				}
				setSelectedInspectionTasks(body);
				setMessage(selectAll ? `Are you sure you want to close the selected Inspection Task(s)?` : `Are you sure you want to close the selected Inspection Task?`);
				setDisplayConfirmModal(true);
			}
		} else {
			setMessage("Select an Inspection Task");
			setDisplayInfoModal(true);
		}		
	}

	const closeSelectedInspectionTasks = () => {
		setCloseTaskInProgress(true);
		let isrequiredquitting = (filterOptions.length && (filterOptions[0] === 'availableTasksToQuit')) ? true : false;
		dispatch(closeInspectionTasks(selectedInspectionTasks, currentStart, isrequiredquitting, setSelectedRows, setSelectAll, setMessage, setDisplayConfirmModal, setDisplayInfoModal, setCloseTaskInProgress));
	}

	const inspectionTaskTemplate = (rowData, options) => {		
		return (
			<div className="table-col">
				{(options.field && ((options.field === "Inspection Task Name") || (options.field === "InspectionTaskName") || (options.field === "inspectionTaskName"))) ?
					(<span className="link-column" onClickCapture={() => selectTask(rowData)}>
						{rowData?.[options?.field]}
					</span>)
					:
					(<div> {rowData?.[options?.field]} </div>)
				}
			</div>
		);
	};

	const selectTask = (taskSelected: any) => {		
		if (taskSelected) { 
			let task  = {
				status: taskStatuses[taskSelected.Status ? taskSelected.Status : taskSelected.inspectionTaskStatus],
				uniqueSiteId: taskSelected.uniqueSiteId ? taskSelected.uniqueSiteId : taskSelected.UniqueSiteId,
				uniqueSystemId:  taskSelected.uniqueSystemId ? taskSelected.uniqueSystemId : taskSelected.UniqueSystemId,
				uniqueInspectionTaskId: taskSelected.uniqueInspectionTaskId ? taskSelected.uniqueInspectionTaskId : taskSelected.UniqueInspectionTaskId,
				uniqueContractId: taskSelected.uniqueContractId ? taskSelected.uniqueContractId : taskSelected.UniqueContractId,
				uniqueCustomerId: taskSelected.uniqueCustomerId ? taskSelected.uniqueCustomerId : taskSelected.UniqueCustomerId,
				systemName: taskSelected.systemName ? taskSelected.systemName : taskSelected['System Name'], 
				contractName: taskSelected.contractName ? taskSelected.contractName : taskSelected['Contract Name'],
				inspectionTaskName: taskSelected.inspectionTaskName ? taskSelected.inspectionTaskName : (taskSelected['Inspection Task Name'] ? taskSelected['Inspection Task Name'] : taskSelected['InspectionTaskName'])
			};
			if (task.uniqueSiteId) {
				setActiveSiteId(task.uniqueSiteId);
				dispatch(setActiveSiteForSite(null));
				dispatch(setAsActiveSiteForSite(task.uniqueSiteId, "", ""));
			}
			if (task.uniqueInspectionTaskId) {
				dispatch(setInspectionSearchId([{uniqueInspectionTaskId:task.uniqueInspectionTaskId,searchBy:"DASHBOARD"}]))	
			}
			if (task.uniqueCustomerId && task.uniqueContractId) {
				let defaultBody = {
					ActiveCustomerId: task.uniqueCustomerId,
					ActiveContractId: task.uniqueContractId,
				};
				dispatch(setDefaultValues(defaultBody));
			}
			setTargetPath(`${APP_ROUTES.INSPECTION_TASKS}`);
			setIsNavigate(true);
			setSelectAll(false);
		}
	}	

	const equipmentTemplate = (rowData, options) => {			
		return (
			<div className="table-col">
				<div> {rowData?.[options?.field]} </div>
			</div>
		);
	}

	const selectEquipment = (equipmentSelected: any) => {
		if (equipmentSelected && equipmentSelected.uniqueInspectionTaskId && equipmentSelected.uniqueSiteId) {
			setActiveSiteId(equipmentSelected.uniqueSiteId);
			dispatch(setActiveSiteForSite(null));
			dispatch(setAsActiveSiteForSite(equipmentSelected.uniqueSiteId, "", ""));
			dispatch(setInspectionSearchId([{uniqueInspectionTaskId:equipmentSelected.uniqueInspectionTaskId,searchBy:"DASHBOARD"}]));
			dispatch(setSelectedFailedEquipment(equipmentSelected.equipmentId))
			if (equipmentSelected.uniqueCustomerId && equipmentSelected.uniqueContractId) {
				let defaultBody = {
					ActiveCustomerId: equipmentSelected.uniqueCustomerId,
					ActiveContractId: equipmentSelected.uniqueContractId,
				};
				dispatch(setDefaultValues(defaultBody));
			}
			setTargetPath(`${APP_ROUTES.INSPECTION_TASKS}`);
			setIsNavigate(true);
		}	
	}

	const contractTemplate = (rowData, options) => {		
		return (
			<div className="table-col">
				{(options.field && ((options.field === "contractNumber"))) ?
					(<span className="link-column" onClickCapture={() => selectContract(rowData)}>
						{rowData?.[options?.field]}
					</span>)
					:
					(<div> {rowData?.[options?.field]} </div>)
				}
			</div>
		);
	};

	const selectContract = (contractSelected: any) => {
		if (contractSelected && contractSelected.uniqueContractId && contractSelected.uniqueSiteId) {
			setActiveSiteId(contractSelected.uniqueSiteId);
			dispatch(setContractSearchId(contractSelected.uniqueContractId));
			dispatch(setActiveSiteForSite(null));
			dispatch(setAsActiveSiteForSite(contractSelected.uniqueSiteId, "", ""));
			setTargetPath(`${APP_ROUTES.CONTRACTS}`);
			setIsNavigate(true);
		}
	}

	return (
		<div className={minToggle ? "inspection-summary grid-max" : "inspection-summary grid-min"}>
			{!minToggle && (
				<>
					<div className="charts-area">
						<div className="reports-generated">
							{(reportSummaryData && reportSummaryData.length) ? 
								(<>
									<div className="title">
										<h5>Reports Generated</h5>
									</div>
									<ResponsiveContainer width="100%" height="100%">
										<BarChart data={reportSummaryData} barCategoryGap="10%"
											style={{ fontSize: '0.7rem' }}>
											<XAxis dataKey="name" fontWeight={"bold"} />
											<YAxis domain={[0, 160]} hide={true} />
											<Legend />
											<Bar dataKey="No Actions" fill="#95B1B0" minPointSize={5} >
												<LabelList dataKey="Percent No Actions" position={"top"} fill="black" textAnchor="middle" angle={(-90)} dy={-18} />
											</Bar>
											<Bar dataKey="Drafted" fill="#7886C2" minPointSize={5} >
												<LabelList dataKey="Percent Drafted" position={"top"} fill="black" textAnchor="middle" angle={(-90)} dy={-18} />
											</Bar>
											<Bar dataKey="Completed" fill="#009999" minPointSize={5} >
												<LabelList dataKey="Percent Completed" position={"top"} fill="black" textAnchor="middle" angle={(-90)} dy={-18} />
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</>) : (<></>)
							}
						</div>
						<div className="equipment-summary">
							{(equipmentSummary && equipmentSummary.length) ? 
								(<>
									<div className="title">
										<h5>Equipment Summary</h5>
									</div>
									<ResponsiveContainer className="bc-wrapper" height="100%">
										<BarChart data={equipmentSummary} barCategoryGap="10%"
											style={{ fontSize: "0.6rem" }}>
											<XAxis dataKey="name" axisLine={false} tick={false} height={0}/>
											<YAxis domain={[0, 150]} hide={true} />
											<Tooltip content={customBarChartTooltip} />
											<Legend />
											<Bar dataKey="Passed" fill="#27B099" minPointSize={5} >
												<LabelList dataKey="Percent Passed" position={"top"} fill="black" textAnchor="middle" angle={(-90)} dy={-15} />
											</Bar>
											<Bar dataKey="Failed" fill="#bd6482" minPointSize={5} >
												<LabelList dataKey="Percent Failed" position={"top"} fill="black" textAnchor="middle" angle={(-90)} dy={-15} />
											</Bar>
											<Bar dataKey="Untested" fill="#95B1B0" minPointSize={5} >
												<LabelList dataKey="Percent Untested" position={"top"} fill="black" textAnchor="middle" angle={(-90)} dy={-15} />
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</>) : (<span>No Equipment data exists</span>)
							}
						</div>
					</div>
					<div className="bt-line"></div>
				</>
			)}
			<div className="tasks-datagrid">
				{<div className="d-flex justify-content-end dashboard-expand-icon">
					{showUI(userPrivileges,"SIETAP_DASHBOARD_KEY_PERFORMANCE_INDICATOR") ? 
						<span className="icon" onClick={() => toggleHandler()}>
							{minToggle ? <AiOutlineShrink /> : <AiOutlineExpandAlt />}
						</span>
						:
						<></>
					}
				</div> 
				}
				<Tabs id="tasks-datagrid-tab" activeKey={key} onSelect={changeTab} mountOnEnter={true} unmountOnExit={false}>
 	<Tab eventKey="activeInspection" title="Active Tasks" className="active-inspection">
					{showUI(userPrivileges, "SIETAP_DASHBOARD_INSPECTIONS") ? 
						(<>
							<div className="active-task-radiobutton">
								<div className="d-flex">
									<div className="field-checkbox mr-4">
										<Checkbox
											inputId="checkbox1"
											name="checkbox1"
											value="unavailableTasksToQuit"
											checked={filterOptions.includes('unavailableTasksToQuit')}
											disabled={closeTaskInProgress}
											onChange={(e) => onChangeFilterOptions(e)}
										/>
										<label htmlFor="checkbox1">Tasks don't require quitting</label>
									</div>
									<div className="field-checkbox">
										<Checkbox
											inputId="checkbox2"
											name="availableTasks"
											value="availableTasksToQuit"
											checked={filterOptions.includes('availableTasksToQuit')}
											disabled={closeTaskInProgress}
											onChange={(e) => onChangeFilterOptions(e)}
										/>
										<label htmlFor="checkbox2">Tasks require quitting</label>
									</div>
								</div>
								{(gridData && gridData.length && (filterOptions.length === 1)) ?
									(<Button
										label="Close Task"
										className="button-icon btn"
										disabled={closeTaskInProgress}
										onClick={() =>closeTaskHandler()}
									/>) 
									: <></>
								}
							</div>
							{((gridData.length) && (filterOptions.length === 1) && (filterOptions[0] === 'unavailableTasksToQuit')) ?
								(<div className="field-checkbox sel-all">
									<Checkbox
										inputId="checkbox3"
										name="allTasks"
										value="selectAll"
										checked={selectAll === true}
										disabled={closeTaskInProgress}
										onChange={(e) => onChangeSelectAll(e)}
									/>
									<label htmlFor="checkbox3">Select All</label>
								</div>)
								: (<></>)
							}
						</>) : (<></>)
					}
						<div className="dashboard-table">
							<DataTableComponent
								rows={gridData}
								cols={columns}
								dataKeyId="InspectionTaskId"
								title={`Active Tasks`}
								paginator={(pageDetails?.totalCount && (pageDetails?.totalCount > pageDetails?.pageCount)) ? true : false}
								pageDetails={pageDetails}
								pageIndex={pageIndex}
								onPageChanged={onPageChanged}
								showCustomSearchCmp
								getSelectedRowData={handleRowSelect}
								onSortData={onSort}
								isSelectionFromParent={true}
								selectedParentRows={selectedRows}
								isTemplateMode={true}
								isTemplateFromParent={true}
								templateFromParent={inspectionTaskTemplate}
							/>
						</div>
					</Tab>
					<Tab eventKey="closedInspection" title="Recently Closed">
						<DataTableComponent
							rows={gridData}
							cols={INSPECTION_TASKS_COL}
							dataKeyId="uniqueInspectionTaskId"
							title={`Recently Closed`}
							paginator={(pageDetails?.totalCount && (pageDetails?.totalCount > pageDetails?.pageCount)) ? true : false}
							pageDetails={pageDetails}
							pageIndex={pageIndex}
							onPageChanged={onPageChanged}
							showCustomSearchCmp
							getSelectedRowData={handleRowSelect}
							onSortData={onSort}
							isTemplateMode={true}
							isTemplateFromParent={true}
							templateFromParent={inspectionTaskTemplate}
						/>
					</Tab>
					<Tab eventKey="completedInspection" title="Recently Completed">
						<DataTableComponent
							rows={gridData}
							cols={INSPECTION_TASKS_COL}
							dataKeyId="uniqueInspectionTaskId"
							title={`Recently Completed`}
							paginator={(pageDetails?.totalCount && (pageDetails?.totalCount > pageDetails?.pageCount)) ? true : false}
							pageDetails={pageDetails}
							pageIndex={pageIndex}
							onPageChanged={onPageChanged}
							showCustomSearchCmp
							getSelectedRowData={handleRowSelect}
							onSortData={onSort}
							isTemplateMode={true}
							isTemplateFromParent={true}
							templateFromParent={inspectionTaskTemplate}
						/>
					</Tab>
					<Tab eventKey="failedEquipment" title="Failed Equipment" className="faild_equipment">
						<div className="dashboard-button-icon-download dash_download">
							<Button
								className="button-icon"
								tooltip="Download"
								tooltipOptions={{ position: "top" }}
								onClick={(e) => downloadRef.current?.toggle(e)}
								iconPos="bottom"
							>
								<FaDownload className="icon" />
								<FaChevronDown className="button-icon-download" />
							</Button>
						</div> 
						<DataTableComponent
							rows={gridData}
							cols={EQUIPMENT_COLS}
							dataKeyId="inpectionTaskId_equipmentId"
							title={`Failed Equipment`}
							paginator={(pageDetails?.totalCount && (pageDetails?.totalCount > pageDetails?.pageCount)) ? true : false}
							pageDetails={pageDetails}
							pageIndex={pageIndex}
							onPageChanged={onPageChanged}
							showCustomSearchCmp
							getSelectedRowData={handleRowSelect}
							onSortData={onSort}
							isTemplateMode={true}
							isTemplateFromParent={true}
							templateFromParent={equipmentTemplate}
						/>
					</Tab>
					<Tab eventKey="expiredContracts" title="Expired Contracts">
						<DataTableComponent
							rows={gridData}
							cols={CONTRACT_COLS}
							dataKeyId="uniqueContractId"
							title={`Expired Contracts`}
							paginator={(pageDetails?.totalCount && (pageDetails?.totalCount > pageDetails?.pageCount)) ? true : false}
							pageDetails={pageDetails}
							pageIndex={pageIndex}
							onPageChanged={onPageChanged}
							showCustomSearchCmp
							getSelectedRowData={handleRowSelect}
							onSortData={onSort}
							isTemplateMode={true}
							isTemplateFromParent={true}
							templateFromParent={contractTemplate}
						/>
					</Tab>
				</Tabs>
			</div>
			<InformationBox
				showInfoModal={displayInfoModal}
				setShowInfoModal={setDisplayInfoModal}
				message={message}
      		/>
			<ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={closeSelectedInspectionTasks}
				message={message}
      		/>
			<PopOver ref={downloadRef}>
				<ul>
				    <li onClick={onReportAllClick}>Report All</li>
                    <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                    <li onClick={onExportAll}>Export All</li>
					<li onClick={onExportHighlightedClick}>Export Highlighted</li>
				</ul>
			</PopOver>
			{showReportModal ? (
                <FaileEquipmentReport showModal={showReportModal}
                    setShowModal={setShowReportModal}
                    reportFlag={reportFlag}
                    selectedRows={selectedRows}
                    sortByColumns={tableColumns}
					sortField={sortField}
					sortOrder={sortOrder}
					 />
            ) : null}
			
		</div>
	);
};

export default memo(InspectionSummary);

