import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from 'primereact/tabview';

import { useAppSelector } from "../../redux/hooks";
import GenerateReports from "./GenerateReports";
import { showUI } from 'service/GeneralUtility';
import Reports from "./Reports";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/AppRoutes";

export interface NavItemProps {
  id: number;
  name: string;
  url: string;
  active: boolean;
}
interface IInspectionReports { }

export const InspectionReports: React.FC<IInspectionReports> = () => {

  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
  
  const [toggleGenerateButton, setToggleGenerateButton] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<any>(showUI(userPrivileges,"SIETAP_FS_GENERATE_REPORTS")?0:1);
  const [toggleGenerateClick, setToggleGenerateClick] = useState<boolean>(false);
  const [disableGenerateButton, setDisableGenerateButton] = useState<boolean>(false);
 


  const navigate = useNavigate();
  const { state } = useLocation() as any;
useEffect(()=>{
  if(activeIndex === 0) {
    navigate(`/${APP_ROUTES.INSPECTION_REPORTS}`, {
      state: "Inspection-Report"
  })
    setToggleGenerateButton(true);
  } else if(activeIndex === 1) {
    navigate(`/${APP_ROUTES.INSPECTION_REPORTS}`, {
      state: "Reports"
  })
    setToggleGenerateButton(false);
  }

},[activeIndex])

  useEffect(() => {
    
    if (state != null)
      setActiveIndex(state == "Reports" ? 1 : 0)
  }, [])

  return (
    <div className="inspection-reports">
      <TabView activeIndex={activeIndex} onTabChange={(indexChangeEvent)=>{setActiveIndex(indexChangeEvent.index)}}  >
         {showUI(userPrivileges,"SIETAP_FS_GENERATE_REPORTS") && 
         <TabPanel header="Generate Reports">
          <GenerateReports toggleGenerateClick={toggleGenerateClick} setToggleGenerateClick={setToggleGenerateClick}/>
        </TabPanel> 
       }
        <TabPanel header="Reports" >
          <Reports />
        </TabPanel>
      </TabView>
      {toggleGenerateButton &&
      <Button label="Generate" className="generate-btn"  
              disabled={disableGenerateButton} 
              onClick={()=>setToggleGenerateClick(true)}/> }
    </div>
  );
};

export default InspectionReports;
