export function getTaskListGridColumns(generalProperties: any) {
    const columnInfo: any = [];
    if (generalProperties != null) {
        for (let i = 0; i < generalProperties?.length; i++) {
            if (
                (generalProperties[i]?.name !== 'Status') && 
                (generalProperties[i]?.name !== 'RowIndex') &&
                (generalProperties[i]?.name !== 'System Name') &&
                (generalProperties[i]?.name !== 'Contract Name') &&
                (generalProperties[i]?.name !== 'UniqueSiteId') &&
                (generalProperties[i]?.name !== "InspectionTaskId") && 
                (generalProperties[i]?.name !== 'UniqueInspectionTaskId') && 
                (generalProperties[i]?.name !== 'UniqueSystemId') &&
                (generalProperties[i]?.name !== 'UniqueContractId') &&
                (generalProperties[i]?.name !== 'UniqueCustomerId')
            ) {
                let data: any = {
                    field: generalProperties[i]?.name,
                    header: generalProperties[i]?.alias,
                    style: (generalProperties[i]?.name.trim() === "Config File") 
                    ? { flex: '1 0 100px' } 
                    : { flex: '1 0 170px' },
                    sortable: true,
                }
                if (generalProperties[i].name && (generalProperties[i].name.toLowerCase() === 'days to upload')) {
                    data.align = 'right';
                    data.style = { flex: '1 0 150px' };
                }
                columnInfo.push(data);
            }
        }
    }
    return columnInfo;
}