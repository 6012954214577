import React, { Fragment } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';

import { APP_ROUTES } from 'constants/AppRoutes';

import Dashboard from 'pages/Dashboard/Dashboard';
import Customers from "pages/Customers/Customers";
import Users from "pages/Users/Users";
import Sites from "../pages/Sites/Sites";
import Search from "pages/Search/Search";
import Contracts from "pages/Contracts/Contracts";
import Systems from "pages/Systems/Systems";
import InspectionReports from "pages/InspectionReports/InspectionReports";
import ManageView from "pages/ManageView/ManageView";
import Organization from "pages/Organization/Organization";
import ManageInspectionReports from 'pages/ManageInspection/Reports/Reports';

import PageNotFound from 'pages/PageNotFound/PageNotFound';

import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar'; 
import { ProgressSpinner } from 'primereact/progressspinner';

import PageMenuBar from 'components/common/PageMenuBar/PageMenuBar';
import HealthClassification from "./ManageLookups/HealthClassification/HealthClassification";
import NFPAClassification from "./ManageLookups/NFPAClassification/NFPA Classification";
import Attributes from "./ManageCustomization/Attributes/Attributes";
import Securitysettings from "./SecuritySettings/SecuritySettings";
import ReportMapping from "./ManageInspection/ReportMapping/ReportMapping";
import InspectionDrawingsMain from "./InspectionTasks/InspectionDrawings/InspectionDrawingsMain";
import InspectionFields from "./ManageInspection/InspectionFields/InspectionFields";
import Copy from "./ManageTools/Copy/Copy";
import Move from "./ManageTools/Move/Move";
import CopyMoveLogs from "./ManageTools/CopyMoveLogs/CopyMoveLogs";
import ReportView from "./ReportView/ReportView";
import SiteReport from "./Reports/SiteReport";
import InspectionTasks from "./InspectionTasks/InspectionTasks";
import ManageDisplaySettings from "./ManageDisplaySettings/ManageDisplaySettings";
import ManageUserDataSettings from "./ManageDataEntrySettings/ManageDataEntrySettings";
import Logbook from "./Administration/Logbook/Logbook";
import  AsBuilts from "./AsBuilts/AsBuilts"
import { useAppSelector } from "../redux/hooks";
import { showUI } from 'service/GeneralUtility';
import ContractsReport from "./Reports/ContractsReport";
import InspectionTaskReport from "./Reports/InspectionTaskReports";
import UserActivityReport from "./Reports/UserActivityReport";
import UserDetailsReport from "./Reports/UserDetailsReport";
import UploadedFinalReports from "./Reports/UploadedFinalReports";
import SapImportStatusReport from "./Reports/SapImportStatusReport";
import InspectionActivityReport from "./Reports/InspectionActivityReport";
import MasterDataReport from "./Reports/MasterDataReport";
import SAPImportStatus from "./ManageTools/SAPImportStatus/SapImportStatus";

import ExpiredContractsReport from "./Reports/ExpiredContractsReport";
import BuildingReport from "./Reports/BuildingReport";
import FloorReport from "./Reports/FloorReport";
import TAPPerformance from "./TAPPerformance/TAPPerformance";
import SalesActivityReport from "./SalesActivityReport/SalesActivityReport";



interface ILayout {

}


const Layout: React.FC<ILayout> = () => {
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const progressFlag = useAppSelector((state) => state.common.progressFlag )
    return (
        <Fragment>
    
            <Sidebar />
            <div className="main-container">
               {progressFlag &&   
                <div className='pre-loader'>
                  <ProgressSpinner />
                </div>}
                <Header />
                <PageMenuBar />

                <Routes>
                    <Route  element={<Dashboard />} />

                    <Route path={APP_ROUTES.DASHBOARD} element={<Dashboard />} />

                    <Route path={APP_ROUTES.ADMINISTRATION}>
                        <Route index element={showUI(userPrivileges,"SIETAP_CLIENTS_MANAGE") ? (<Customers/>) : (<Sites />)} />
                        <Route path='*' element={<PageNotFound />} />
                    </Route>

                    <Route path={APP_ROUTES.INSPECTION}>
                        <Route index element={<InspectionTasks />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Route>

                    <Route path={APP_ROUTES.CUSTOMERS} element={<Customers />} />
                    <Route path="/" element={<Navigate to= {APP_ROUTES.INSPECTION_TASKS} replace />} />
                    <Route path={APP_ROUTES.USERS} element={<Users />} />
                    <Route path={APP_ROUTES.SITES} element={<Sites />} />
                    <Route path={APP_ROUTES.SEARCH} element={<Search />} />
                    <Route path={APP_ROUTES.INSPECTION_TASKS} element={<InspectionTasks />} />
                    <Route path={APP_ROUTES.CONTRACTS} element={<Contracts />} />
                    <Route path={APP_ROUTES.SYSTEMS} element={<Systems />} />

                    <Route path={APP_ROUTES.INSPECTION_REPORTS} element={<InspectionReports />} />


                    <Route path={APP_ROUTES.MANAGE_VIEW} element={<ManageView />} />
                    <Route path={APP_ROUTES.MANAGE_ORGANIZATION} element={<Organization />} />
                    <Route path={APP_ROUTES.TOOLS_COPY} element={<Copy/>} />
                    <Route path={APP_ROUTES.TOOLS_Move} element={<Move/>} />
                    <Route path={APP_ROUTES.TOOLS_COPY_MOVE_LOGS} element={<CopyMoveLogs />} />
                    <Route path={APP_ROUTES.SAP_IMPORT_STATUS} element={<SAPImportStatus />} />
                    <Route path={APP_ROUTES.MANAGE_INSPECTION_REPORTS} element={<ManageInspectionReports />} />
                   

                    <Route path={APP_ROUTES.MANAGE_INSPECTION_REPORT_MAPPING} element={<ReportMapping />}>               
                    </Route>

                    <Route path={APP_ROUTES.MANAGE_LOOKUPS_HEALTHCLASSIFICATION} element={<HealthClassification />} />
                    <Route path={APP_ROUTES.MANAGE_LOOKUPS_NFPA_CLASSIFICATION} element={<NFPAClassification />} />
                    <Route path={APP_ROUTES.CUSTOMIZATION_ATTRIBUTES} element={<Attributes />} />
                    <Route path={APP_ROUTES.SECURITY_SETTINGS} element={<Securitysettings />} />

                    <Route path={APP_ROUTES.INSPECTION_DRAWINGS} element={<InspectionDrawingsMain />} />
                  
                   
                    <Route path={APP_ROUTES.REPORT_VIEW} element={<ReportView />} />
                    <Route path={APP_ROUTES.SITE_REPORT} element={<SiteReport />} />
                    <Route path={APP_ROUTES.CONTRACTS_REPORT} element={<ContractsReport />} />
                    <Route path={APP_ROUTES.INSPECTION_TASK_REPORT} element={<InspectionTaskReport />} />
                    <Route path={APP_ROUTES.USER_ACTIVITY_REPORT} element={<UserActivityReport />} />
                    <Route path={APP_ROUTES.USER_DETAILS_REPORT} element={<UserDetailsReport />} />
                    <Route path={APP_ROUTES.UPLOADED_FINAL_REPORTS} element={<UploadedFinalReports />} />
                    <Route path={APP_ROUTES.SAP_IMPORT_STATUS_REPORT} element={<SapImportStatusReport />} />
                    <Route path={APP_ROUTES.INSPECTION_ACTIVITY_REPORT} element={<InspectionActivityReport />} />
                    <Route path={APP_ROUTES.MASTER_DATA_REPORT} element={<MasterDataReport />} />
                    <Route path={APP_ROUTES.EXPIRED_CONTRACTS_REPORT} element={<ExpiredContractsReport />} />
                    <Route path={APP_ROUTES.TAP_PERFORMACE} element={<TAPPerformance />} />
                    <Route path={APP_ROUTES.SALES_ACTIVITY_REPORT} element={<SalesActivityReport />} />
                 
                    <Route path={APP_ROUTES.INSPECTION_FIELDS} element={<InspectionFields />} />                   

                    <Route path={APP_ROUTES.MANAGE_DISPLAY_SETTINGS} element={<ManageDisplaySettings />} />
                    <Route path={APP_ROUTES.MANAGE_DATAENTRY_SETTINGS} element={<ManageUserDataSettings />} />

                    <Route path={APP_ROUTES.LOG_BOOK} element={<Logbook />} />
                    <Route path={APP_ROUTES.AS_BUILTS} element={<AsBuilts />} />
                    <Route path={APP_ROUTES.BUILDING_REPORT} element={<BuildingReport />} />
                    <Route path={APP_ROUTES.FLOOR_REPORT} element={<FloorReport />} />
                    <Route path='*' element={<PageNotFound />} />
                </Routes>
            </div>
        </Fragment>
    )
}
export default Layout;